import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 846 900">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M408.000000,901.000000 
	C272.000000,901.000000 136.500000,901.000000 1.000000,901.000000 
	C1.000000,601.000000 1.000000,301.000000 1.000000,1.000000 
	C283.000000,1.000000 565.000000,1.000000 847.000000,1.000000 
	C847.000000,301.000000 847.000000,601.000000 847.000000,901.000000 
	C700.833313,901.000000 554.666687,901.000000 408.000000,901.000000 
M339.009521,596.001953 
	C339.009521,596.001953 339.077209,596.051025 338.523468,596.231873 
	C338.407684,596.582764 338.291901,596.933716 338.133759,597.865601 
	C338.020020,598.235962 337.906311,598.606384 337.220734,599.257202 
	C335.832306,598.497375 334.443878,597.737549 332.638367,596.554443 
	C332.102417,595.971863 331.566467,595.389282 330.756470,594.349487 
	C330.420319,594.286194 330.084198,594.222900 329.058868,594.080200 
	C328.377655,593.955322 327.696442,593.830383 326.564850,593.224304 
	C324.375244,593.445923 322.185608,593.667603 319.394135,594.104492 
	C318.599121,594.403870 317.804108,594.703186 316.176514,594.979492 
	C315.066193,595.012573 313.955841,595.045654 312.552673,594.479187 
	C310.163635,594.333740 307.774597,594.188293 306.084137,594.085388 
	C304.878540,597.245117 303.962555,599.645874 303.046570,602.046631 
	C301.448364,600.648804 300.957397,599.477905 300.594391,598.268555 
	C299.870361,595.856323 299.326324,593.822083 295.829010,593.875000 
	C292.052155,593.932190 292.204742,596.584106 291.441742,598.782593 
	C290.828857,600.548645 290.371704,602.368652 289.845459,604.164673 
	C288.101166,601.424072 288.125336,598.273499 286.641846,596.203186 
	C285.511261,594.625305 282.468323,594.417664 280.276154,593.600464 
	C280.018982,594.100830 279.761810,594.601196 279.504669,595.101562 
	C281.647736,602.037476 283.790802,608.973389 285.953094,615.971436 
	C288.196167,615.971436 290.162994,615.971436 292.277222,615.971436 
	C292.836823,614.627747 293.467041,613.332581 293.914276,611.977051 
	C294.333405,610.706848 294.558807,609.372620 294.869446,608.066589 
	C296.703339,609.403015 297.812134,610.789734 297.920349,612.250671 
	C298.174377,615.680481 300.168152,616.029175 302.974426,616.219482 
	C306.086151,616.430603 307.041931,614.889160 307.570007,612.297302 
	C307.932373,610.518982 308.635529,608.810181 309.868256,606.867737 
	C311.816376,604.958008 310.669983,600.279175 315.689270,600.792908 
	C315.689270,600.792908 316.023499,600.963562 316.037079,601.763916 
	C316.016907,602.830017 315.996735,603.896179 315.990936,605.006531 
	C315.990936,605.006531 315.955231,604.976013 315.483063,605.044373 
	C315.165070,605.188110 314.980042,605.432495 314.939056,605.925842 
	C314.939056,605.925842 314.790894,605.938660 314.332825,606.181702 
	C314.256683,606.505310 314.180573,606.828979 314.045135,607.836365 
	C313.462341,614.147461 315.647522,616.803101 321.023285,616.496155 
	C325.360107,616.248474 329.705688,616.154358 334.820251,616.030823 
	C336.319061,615.622437 337.817871,615.214050 339.968079,615.131226 
	C343.857483,615.398865 347.902924,616.408630 351.573853,615.636902 
	C353.559235,615.219543 355.893677,611.906921 356.230927,609.628174 
	C356.507050,607.762695 354.239929,605.351379 352.745056,603.463257 
	C351.976440,602.492493 350.432159,602.135864 348.108734,600.901611 
	C351.330353,600.245972 353.316376,599.841736 356.345367,599.225281 
	C354.688446,597.248657 353.601746,594.746948 352.311005,594.636841 
	C348.315063,594.295898 344.241455,594.865173 339.644562,595.174316 
	C339.446320,595.474609 339.248077,595.774902 339.009521,596.001953 
M240.778931,595.677856 
	C240.467789,595.459106 240.156631,595.240417 239.256058,594.709351 
	C235.860336,593.005676 232.732620,593.063904 229.551651,595.997620 
	C229.378967,595.984802 229.206299,595.971924 228.746368,595.267578 
	C225.931747,593.573120 223.230698,592.308167 221.982895,597.945679 
	C222.114273,602.949341 222.245667,607.953064 222.146469,613.639648 
	C222.127686,614.360107 222.108902,615.080627 222.329056,616.520020 
	C224.696121,618.213135 227.024246,619.409485 228.999313,616.000000 
	C228.999313,616.000000 228.999725,615.991882 229.702377,616.011353 
	C230.184357,615.395874 231.042160,614.803772 231.082245,614.160706 
	C231.242386,611.591553 231.148315,609.006531 231.148315,606.099976 
	C232.642853,606.517700 233.564682,606.775330 234.489059,607.715637 
	C234.436325,608.166077 234.383606,608.616577 233.899780,609.536804 
	C234.013275,611.475403 234.126755,613.414062 234.649643,615.665588 
	C234.761719,615.795776 234.873810,615.925903 235.126144,616.753296 
	C236.993240,618.622009 238.676117,618.865662 240.658936,616.046387 
	C241.048721,615.868408 241.438507,615.690430 241.896393,616.476074 
	C241.992157,618.090027 242.087921,619.704041 242.215378,622.069153 
	C242.416443,626.277039 245.944977,625.248840 247.789139,624.769348 
	C249.805099,624.245300 252.866440,622.111450 252.924149,620.583069 
	C253.356857,609.119507 253.159760,597.632141 253.159760,586.092529 
	C250.401840,586.092529 247.989304,586.092529 245.901657,586.092529 
	C245.901657,590.295898 245.901657,594.138245 245.129547,598.010864 
	C243.843414,597.972778 242.557266,597.934753 241.114914,597.936279 
	C241.114914,597.936279 240.970901,598.008606 241.041763,597.424194 
	C240.986130,597.035828 240.930511,596.647400 240.778931,595.677856 
M528.996399,615.995850 
	C528.996399,615.995850 528.991821,616.020813 529.153076,616.775269 
	C531.943176,618.335266 534.694092,619.489868 537.331421,615.526123 
	C537.475769,615.390503 537.620056,615.254944 538.481628,615.057556 
	C539.442383,614.975098 540.403137,614.892639 541.852234,614.972717 
	C542.241028,615.302734 542.629761,615.632812 543.155273,616.721924 
	C547.099060,616.515869 551.042847,616.309875 555.788452,616.014709 
	C556.859375,616.073975 557.930237,616.133240 559.192993,616.492432 
	C559.459778,616.376343 559.726501,616.260254 560.640930,615.576416 
	C560.514526,611.796326 560.334106,608.017151 560.272583,604.236023 
	C560.129211,595.416748 557.674316,593.074097 548.879517,593.762390 
	C546.949646,593.913391 545.063965,594.629578 542.343445,595.017944 
	C540.523254,594.710510 538.703064,594.403015 536.992859,594.001465 
	C536.992859,594.001465 536.896545,594.109314 536.889526,593.403503 
	C535.873108,591.925598 534.856689,590.447754 533.705566,588.438660 
	C533.734802,588.076904 533.764099,587.715088 533.872253,586.577148 
	C530.279663,583.988342 528.681641,584.628296 527.048035,589.041504 
	C527.048035,589.041504 527.239868,589.217712 526.504517,589.154358 
	C524.266052,591.173828 523.062683,594.358337 518.711121,593.424194 
	C515.325684,592.697510 511.587982,593.612122 507.317230,594.038940 
	C505.735626,595.037292 503.989014,595.849670 502.608551,597.074707 
	C500.138977,599.266235 501.189148,600.527771 504.011963,601.856689 
	C504.114929,602.683533 504.217896,603.510315 504.320923,604.303223 
	C504.320923,604.303223 504.287811,604.309631 503.718811,604.251831 
	C503.484100,604.528625 503.249390,604.805420 502.599518,605.672791 
	C500.117767,611.127319 500.802887,615.398254 505.019196,615.813599 
	C510.664276,616.369751 516.466614,615.329651 522.690063,614.469727 
	C522.325989,612.618774 521.961975,610.767761 521.422058,608.313477 
	C521.484192,607.904175 521.546326,607.494934 522.193237,606.805054 
	C522.792603,605.677002 523.391968,604.548950 524.019409,603.368042 
	C525.007996,606.870605 525.879761,609.959351 527.082275,613.558289 
	C527.384460,614.085632 527.686646,614.612976 528.149963,615.589783 
	C528.352783,615.871094 528.627563,616.011353 528.996399,615.995850 
M635.053833,600.971497 
	C635.053833,600.971497 635.231384,600.949768 635.306763,601.881470 
	C635.306763,606.537659 635.306763,611.193909 635.306763,616.880615 
	C638.320618,614.890076 641.688293,618.438904 642.843750,613.202271 
	C642.802368,612.466187 642.760925,611.730042 642.935791,610.341919 
	C642.861023,609.896118 642.786255,609.450317 642.832886,608.212158 
	C642.795898,607.146179 642.758911,606.080261 642.969238,604.350952 
	C643.235413,603.211182 643.501648,602.071472 643.944824,600.921814 
	C643.944824,600.921814 643.966125,600.745850 644.494080,600.576843 
	C645.001404,600.400024 645.508667,600.223267 646.533325,600.195129 
	C646.776062,600.459717 647.018799,600.724365 647.429321,601.908997 
	C647.429321,606.521790 647.429321,611.134583 647.429321,615.844788 
	C650.387146,615.844788 652.612366,615.844788 654.863037,615.844788 
	C654.863037,610.272339 655.198059,604.942139 654.769226,599.674133 
	C654.336670,594.360718 649.773743,592.125488 644.949829,594.422913 
	C643.950378,594.898926 643.071716,595.628418 642.137390,596.241028 
	C642.081482,595.765930 642.025635,595.290894 641.969727,594.815796 
	C636.057190,594.815796 630.144592,594.815796 623.839478,594.815796 
	C623.839478,601.518799 623.839478,608.578430 623.839478,615.722778 
	C626.257568,615.722778 628.453064,615.722778 630.674744,615.722778 
	C630.674744,612.529114 630.674744,609.734253 630.808716,606.218140 
	C630.780212,605.486267 630.751770,604.754456 631.045715,603.305969 
	C632.377747,602.468323 633.709778,601.630676 635.053833,600.971497 
M479.000000,597.000000 
	C479.000000,597.000000 479.023224,597.021606 478.245911,597.115601 
	C476.869049,597.942139 475.492188,598.768677 474.021179,598.703369 
	C473.907623,596.124695 473.794067,593.545959 473.822113,590.347229 
	C473.773499,589.928894 473.724884,589.510620 473.913849,588.320129 
	C472.782776,583.345886 469.312683,586.803223 466.902222,585.930420 
	C466.902222,596.186279 466.902222,605.988953 466.902222,616.100281 
	C470.251434,615.701416 474.421234,617.890747 474.896667,611.993408 
	C475.942719,611.992004 476.988770,611.990601 478.378204,612.592346 
	C479.987885,613.804382 481.507812,615.884155 483.224518,616.061768 
	C487.909210,616.546448 492.676910,616.228516 497.717865,616.228516 
	C497.717865,605.698059 497.717865,596.127625 497.722290,585.780640 
	C495.431580,583.208801 493.192017,583.201477 490.737122,586.260742 
	C490.530731,586.470032 490.352631,586.701111 489.571991,587.413208 
	C489.225159,591.342773 487.674561,593.701172 483.134766,593.044312 
	C480.419128,592.651367 478.729584,593.973267 479.000000,597.000000 
M277.412750,465.025970 
	C277.356659,465.337128 277.300568,465.648315 277.035431,466.881012 
	C277.093475,482.268585 277.151550,497.656189 276.953735,513.907410 
	C276.953735,516.148254 276.953735,518.389099 276.953735,520.553711 
	C296.061310,520.553711 314.408386,520.553711 332.863708,520.553711 
	C332.863708,501.815491 332.863708,483.431610 332.863708,464.431122 
	C330.153564,464.431122 327.701843,464.411438 325.250519,464.434296 
	C309.822784,464.578033 294.395142,464.731262 278.420410,464.723755 
	C278.260925,464.826508 278.101410,464.929291 277.412750,465.025970 
M458.051361,277.009003 
	C423.025055,276.966827 387.998779,276.915802 352.972473,276.896973 
	C349.665985,276.895203 346.359375,277.095795 342.372192,277.004883 
	C341.914215,277.090210 341.456268,277.175568 340.218506,277.218170 
	C339.947601,277.613342 339.439697,278.008636 339.439941,278.403625 
	C339.449677,294.220825 339.502472,310.037964 339.581238,325.855011 
	C339.582489,326.108368 339.974213,326.359772 340.437988,326.918335 
	C341.837677,326.918335 343.489532,326.918060 345.141388,326.918365 
	C378.460236,326.924561 411.779388,326.863312 445.097595,327.012756 
	C449.877411,327.034180 454.833588,327.439484 459.400360,328.744415 
	C486.910767,336.605469 505.617737,354.611023 512.423157,381.987549 
	C519.160156,409.088715 511.575836,433.512329 489.714874,452.172089 
	C482.227203,458.563293 474.015625,464.106445 466.134796,470.037048 
	C465.629822,469.717957 465.124878,469.398865 464.619934,469.079773 
	C464.619934,438.447144 464.619934,407.814484 464.619934,377.267212 
	C445.390472,377.267212 426.975189,377.267212 408.576538,377.267212 
	C408.576538,425.282166 408.576538,472.962036 408.576538,520.706421 
	C425.018494,520.706421 441.003876,520.760681 456.988281,520.657959 
	C459.963287,520.638855 462.957001,520.119690 465.903625,519.609436 
	C495.453583,514.492798 519.369202,499.207123 539.970276,478.156555 
	C551.005066,466.881042 559.775757,454.027283 565.784119,439.333557 
	C573.536560,420.374634 571.434509,401.018677 567.821899,381.785339 
	C561.221863,346.646851 545.407043,316.817932 515.454895,295.907684 
	C499.163727,284.534485 480.945831,278.235077 460.316101,277.176331 
	C459.858337,277.212555 459.400574,277.248749 458.051361,277.009003 
M439.314026,614.030457 
	C440.392242,613.900452 441.470459,613.770447 442.822601,613.818298 
	C442.822601,613.818298 442.953217,614.117615 442.950684,614.864563 
	C445.074738,618.667847 447.781250,619.139954 451.671753,616.017456 
	C452.105652,615.962830 452.539581,615.908142 453.372986,616.392090 
	C454.575836,616.279541 455.778687,616.166992 457.785522,616.041809 
	C458.853607,616.085693 459.921722,616.129578 461.189087,616.490784 
	C461.458862,616.358337 461.728607,616.225891 462.563904,615.470764 
	C462.476501,611.194275 462.401398,606.917480 462.297913,602.641357 
	C462.206604,598.867371 461.790466,595.266418 457.197357,594.215027 
	C450.677643,592.722595 445.105988,594.178467 442.169586,597.957886 
	C440.909454,596.975647 439.649323,595.993347 437.770142,594.430115 
	C435.695801,594.430115 433.621490,594.430115 431.353699,594.430115 
	C431.353699,601.713806 431.353699,608.618103 431.353699,616.887146 
	C434.189545,615.789917 436.348022,614.954773 439.314026,614.030457 
M413.387360,594.085754 
	C412.582306,594.387695 411.777252,594.689697 410.503632,595.068542 
	C410.195831,595.223938 410.017029,595.472290 409.677002,596.076416 
	C409.677002,596.076416 409.302673,596.191345 408.527374,596.188599 
	C408.096832,597.240234 407.666260,598.291809 407.169983,599.903503 
	C407.006500,600.239868 406.843048,600.576172 405.909546,600.957031 
	C405.144196,601.054443 404.378845,601.151855 403.160522,600.701782 
	C402.366058,599.163513 401.920258,597.231445 400.711395,596.161194 
	C397.159058,593.016235 390.291626,592.859924 386.797424,595.485779 
	C383.243683,598.156372 381.463379,605.423340 383.219940,610.088196 
	C384.903046,614.557922 388.933289,616.926880 394.331757,616.523621 
	C395.810852,616.413086 397.272430,616.068481 399.539124,615.959778 
	C400.570435,614.802063 401.601746,613.644348 402.805237,611.935486 
	C402.815979,611.369751 402.826752,610.803955 402.982178,609.542297 
	C404.727692,605.996826 405.661133,609.420471 407.172974,610.679565 
	C409.545837,615.420959 414.826813,617.720642 420.495148,616.275330 
	C424.483063,615.258423 427.226929,613.046082 427.068298,607.159790 
	C424.199585,608.100403 421.965607,609.047119 419.635468,609.520935 
	C418.104980,609.832153 415.100800,609.697327 415.040527,609.357300 
	C414.574036,606.726929 414.394592,603.955750 414.802765,601.333496 
	C414.888031,600.785889 417.813354,600.308716 419.371887,600.447937 
	C421.784637,600.663635 424.147491,601.437683 427.152405,602.114868 
	C426.438721,596.410278 423.489624,594.828491 419.484344,593.272217 
	C417.653381,593.465271 415.822388,593.658325 413.387360,594.085754 
M395.792145,518.123474 
	C395.792145,487.895416 395.792145,457.667358 395.792145,427.373413 
	C376.637299,427.373413 358.139160,427.373413 339.669739,427.373413 
	C339.669739,458.712738 339.669739,489.594055 339.669739,520.903687 
	C357.795715,520.903687 375.569092,520.927856 393.341705,520.824829 
	C394.141174,520.820190 394.933990,519.662842 395.792145,518.123474 
M221.305664,579.854309 
	C225.007446,580.121948 228.709213,580.389648 233.053421,580.703796 
	C233.053421,572.264526 233.053421,564.669678 233.053421,555.634033 
	C237.751801,563.731018 241.465134,570.580627 245.688202,577.099976 
	C246.779770,578.785095 249.352768,579.913574 251.458252,580.430542 
	C253.764496,580.996887 256.317261,580.559448 258.708374,580.559448 
	C258.708374,564.543030 258.708374,549.152405 258.708374,533.820679 
	C254.832581,533.820679 251.292740,533.820679 247.152832,533.820679 
	C247.152832,541.814026 247.152832,549.419189 247.152832,557.024292 
	C242.171967,550.185303 238.180801,543.160095 234.048172,536.219055 
	C233.384109,535.103760 232.161407,533.695801 231.071304,533.577087 
	C227.843033,533.225525 224.551895,533.451233 221.042603,533.451233 
	C221.042603,549.054932 221.042603,564.018555 221.305664,579.854309 
M188.073013,564.300903 
	C186.858398,562.788940 185.643799,561.276978 184.307846,559.613953 
	C185.415466,558.874451 186.543732,558.238098 187.539230,557.436890 
	C192.490372,553.451904 192.797897,548.030945 191.767334,542.431152 
	C190.990814,538.211731 188.418839,534.517456 184.110718,534.258301 
	C175.067688,533.714417 165.969177,534.093201 156.750244,534.093201 
	C156.750244,549.402527 156.750244,564.762329 156.750244,580.180298 
	C160.973404,580.180298 164.858597,580.180298 169.204208,580.180298 
	C169.204208,573.756958 169.204208,567.714905 169.204208,561.672913 
	C169.613022,561.435364 170.021835,561.197815 170.430649,560.960266 
	C171.872299,562.490967 173.704239,563.809387 174.675766,565.595764 
	C176.811157,569.522095 178.408646,573.737854 180.454315,577.717957 
	C181.042435,578.862183 182.348511,580.307861 183.433319,580.404602 
	C187.160034,580.736755 190.934586,580.532166 195.605026,580.532166 
	C192.965622,574.788879 190.707031,569.874207 188.073013,564.300903 
M571.463135,574.971497 
	C578.551636,564.196655 577.715149,552.935059 573.095215,541.674988 
	C571.230286,537.129639 567.323181,534.176208 562.313293,533.942017 
	C555.057129,533.602966 547.773376,533.855225 540.239990,533.855225 
	C540.239990,549.302124 540.239990,564.677917 540.239990,580.196472 
	C547.460144,580.196472 554.453857,580.689270 561.324646,579.984192 
	C564.655273,579.642517 567.755432,577.055359 571.463135,574.971497 
M380.658752,578.966858 
	C380.658752,576.033569 380.658752,573.100281 380.658752,569.711060 
	C373.398224,569.711060 366.674561,569.711060 359.868347,569.711060 
	C359.868347,566.503906 359.868347,563.782227 359.868347,560.733948 
	C366.099274,560.733948 372.017731,560.733948 378.219208,560.733948 
	C378.219208,557.338318 378.219208,554.279785 378.219208,550.844666 
	C371.943665,550.844666 366.008636,550.844666 359.890656,550.844666 
	C359.890656,548.264526 359.890656,546.072388 359.890656,543.359131 
	C366.772003,543.359131 373.320343,543.359131 379.878418,543.359131 
	C379.878418,539.871155 379.878418,536.914612 379.878418,533.718140 
	C368.960449,533.718140 358.244995,533.718140 347.440155,533.718140 
	C347.440155,549.396606 347.440155,564.765137 347.440155,580.567200 
	C351.892639,580.567200 356.025665,580.657349 360.153351,580.546692 
	C366.907959,580.365601 373.659058,580.053772 380.658752,578.966858 
M288.469269,532.971985 
	C284.977386,533.010498 281.432037,532.676392 278.005188,533.168091 
	C271.604370,534.086670 267.494843,538.510559 266.665710,544.651550 
	C265.837891,550.782776 268.323792,555.602173 274.249115,558.423096 
	C277.531219,559.985657 281.280304,560.618591 284.449463,562.347656 
	C286.329803,563.373596 288.081238,565.530151 288.720581,567.548767 
	C289.027710,568.518555 286.635834,571.524536 285.444702,571.536865 
	C283.097137,571.561279 280.454987,570.543335 278.480652,569.171082 
	C277.251526,568.316772 277.023041,566.022827 276.249664,564.141907 
	C272.613983,564.417908 269.015137,564.691040 265.216278,564.979370 
	C265.216278,566.015198 265.085938,566.843628 265.236023,567.617615 
	C267.220551,577.851196 272.930115,581.639771 285.544891,581.140442 
	C293.733063,580.816345 299.530396,575.871399 300.819214,568.112061 
	C302.121033,560.274536 298.657532,554.362122 290.778839,551.360596 
	C287.533234,550.124146 284.026886,549.523315 280.879883,548.100830 
	C279.618134,547.530457 279.004242,545.526855 278.095520,544.175659 
	C279.520813,543.285461 281.339508,541.349548 282.294739,541.708862 
	C284.770203,542.639954 286.865387,544.582031 289.112366,546.120483 
	C288.889435,546.545288 288.666534,546.970093 288.443604,547.394897 
	C292.034698,547.163757 295.625793,546.932678 299.198761,546.702698 
	C298.893646,538.982971 296.513031,535.878418 288.469269,532.971985 
M457.651520,549.866455 
	C454.469208,559.917603 451.286865,569.968811 447.935791,580.552917 
	C450.814514,580.552917 452.977875,580.351807 455.089508,580.596558 
	C458.984222,581.048035 461.272491,579.914734 461.894714,575.654785 
	C462.055542,574.553650 464.067200,573.193054 465.425232,572.916321 
	C467.659027,572.461304 470.067871,572.958374 472.376129,572.753052 
	C475.346710,572.488831 476.947723,573.324585 477.397827,576.583618 
	C477.592133,577.990479 479.095612,580.139648 480.252777,580.315430 
	C483.585602,580.821472 487.044952,580.494141 491.116028,580.494141 
	C486.235077,565.062439 481.634674,550.399780 476.889099,535.784241 
	C476.578796,534.828735 475.248260,533.736206 474.236847,533.549500 
	C464.847595,531.815979 462.947876,532.997925 460.104370,541.958069 
	C459.351074,544.331909 458.641205,546.719543 457.651520,549.866455 
M588.962097,541.207153 
	C584.864929,554.119995 580.767700,567.032898 576.634705,580.058594 
	C588.185059,581.443298 588.185059,581.443298 591.376221,572.799072 
	C595.024231,572.799072 598.851624,572.570618 602.620117,572.941162 
	C603.721069,573.049438 605.394531,574.774109 605.532043,575.911804 
	C606.036133,580.084839 608.316467,581.020569 612.027710,580.601685 
	C614.289429,580.346436 616.603394,580.554504 618.766663,580.554504 
	C618.981873,579.789551 619.217407,579.431274 619.136353,579.172119 
	C614.621094,564.741272 610.093079,550.314331 605.505737,535.906311 
	C605.234680,535.054810 604.502625,533.733215 603.919983,533.697693 
	C600.106384,533.464966 596.077942,532.821045 592.537720,533.809082 
	C590.929321,534.257935 590.276062,538.129089 588.962097,541.207153 
M665.294922,538.582458 
	C660.980896,552.298279 656.666931,566.014160 652.202087,580.209473 
	C656.729492,580.209473 660.632812,580.209473 664.631287,580.209473 
	C665.248291,577.915466 665.880005,575.566895 666.565613,573.017944 
	C671.319580,573.017944 675.909302,573.017944 680.491089,573.017944 
	C681.200073,575.583801 681.844360,577.915466 682.441650,580.076904 
	C686.807617,580.076904 690.799561,580.076904 695.158447,580.076904 
	C690.132996,564.236816 685.286133,548.959595 680.363831,533.444763 
	C677.376282,533.444763 674.525269,533.702148 671.741089,533.380676 
	C668.125427,532.963257 666.013489,534.121094 665.294922,538.582458 
M410.878876,545.489197 
	C411.844391,546.979187 412.809906,548.469238 413.822479,550.031860 
	C417.219269,549.097656 420.371338,548.230713 423.489105,547.373230 
	C422.142761,539.530579 417.927368,534.507263 411.119110,533.270874 
	C403.215942,531.835571 395.717987,532.673279 390.457977,539.738647 
	C383.524475,549.051819 384.319794,566.932617 391.880310,575.758606 
	C396.361633,580.990051 405.426849,582.707153 413.145813,580.399597 
	C418.372040,578.837219 424.458557,571.023743 423.761932,564.891479 
	C420.645752,563.812927 417.401031,562.689880 413.889618,561.474487 
	C413.480530,562.631348 413.162018,563.525391 412.848145,564.420959 
	C411.595276,567.995483 409.626007,570.497009 405.303497,570.262939 
	C400.869141,570.022888 399.077942,567.096924 398.761200,563.438660 
	C398.378815,559.021729 398.152191,554.409607 399.007141,550.110535 
	C400.241669,543.902161 404.239075,542.408875 410.878876,545.489197 
M307.065125,561.499390 
	C307.065125,567.733948 307.065125,573.968506 307.065125,580.211304 
	C311.583344,580.211304 315.320343,580.211304 319.470825,580.211304 
	C319.470825,574.468567 319.470825,569.048706 319.470825,563.026672 
	C322.656555,563.026672 325.439087,563.172913 328.201324,562.998047 
	C335.309296,562.547974 340.040649,557.737183 340.682098,550.497620 
	C341.470123,541.603333 338.452271,534.865112 332.053894,534.213013 
	C323.903290,533.382385 315.603882,534.011780 307.065186,534.011780 
	C307.065186,542.905823 307.065186,551.702759 307.065125,561.499390 
M618.688477,545.052856 
	C622.142944,545.052856 625.597412,545.052856 629.595215,545.052856 
	C629.595215,557.008423 629.595215,568.545227 629.595215,580.278992 
	C633.887146,580.278992 637.623657,580.278992 641.885315,580.278992 
	C641.885315,568.483337 641.885315,556.953125 641.885315,544.931885 
	C646.272278,544.931885 650.155151,544.931885 653.934326,544.931885 
	C653.934326,540.844482 653.934326,537.267822 653.934326,533.664185 
	C641.519775,533.664185 629.444031,533.664185 616.701965,533.664185 
	C617.061462,537.630005 617.394836,541.308472 618.688477,545.052856 
M510.498962,580.548218 
	C515.618347,580.548218 520.737732,580.548218 526.129028,580.548218 
	C526.129028,576.433838 526.129028,572.850952 526.129028,568.915649 
	C519.819336,568.915649 513.693298,568.915649 507.142090,568.915649 
	C507.142090,556.967896 507.142090,545.363647 507.142090,533.696045 
	C502.937378,533.696045 499.173431,533.696045 495.293823,533.696045 
	C495.293823,549.351562 495.293823,564.742920 495.293823,580.546082 
	C500.220856,580.546082 504.862701,580.546082 510.498962,580.548218 
M443.094086,557.499451 
	C443.094086,549.588318 443.094086,541.677185 443.094086,533.747070 
	C438.663452,533.747070 434.932770,533.747070 431.219391,533.747070 
	C431.219391,549.425415 431.219391,564.790771 431.219391,580.216797 
	C435.320862,580.216797 439.057556,580.216797 443.094086,580.216797 
	C443.094086,572.754639 443.094086,565.626831 443.094086,557.499451 
M199.945587,534.934814 
	C199.945587,550.005005 199.945587,565.075195 199.945587,580.213135 
	C204.312057,580.213135 208.052109,580.213135 211.796478,580.213135 
	C211.796478,564.501526 211.796478,549.120544 211.796478,533.187866 
	C207.679779,533.494568 203.938736,533.773254 199.945587,534.934814 
M379.339905,602.697815 
	C378.461456,599.168091 378.516541,594.572388 373.810333,594.064758 
	C369.387177,593.587708 364.829742,594.355530 359.953552,594.594055 
	C359.953552,603.517639 359.943848,612.280518 359.967468,621.043335 
	C359.970062,621.994690 360.165039,622.945496 360.292023,624.087830 
	C362.594849,624.087830 364.667053,624.087830 366.848114,624.087830 
	C367.018585,621.077576 367.173370,618.344543 367.330597,615.568176 
	C374.900146,617.071228 377.229828,616.343384 378.561615,611.690369 
	C379.341217,608.966553 379.454163,606.051880 379.339905,602.697815 
M258.595398,600.606812 
	C262.293915,600.238281 265.992432,599.869751 269.690979,599.501221 
	C269.778229,600.131897 269.865509,600.762634 269.952789,601.393372 
	C267.044769,602.327637 264.138763,603.268127 261.228302,604.194580 
	C257.505676,605.379639 255.979919,608.390930 257.281738,611.558960 
	C258.145538,613.661011 261.009949,616.083313 263.165924,616.276672 
	C267.797913,616.692322 272.550873,615.759521 277.254974,615.378601 
	C277.254974,610.993103 277.301117,606.532776 277.240204,602.073853 
	C277.192291,598.567993 276.507263,595.039856 272.497284,594.285522 
	C267.819550,593.405640 262.802887,592.445129 258.954590,596.584839 
	C258.215363,597.380066 258.319550,598.959351 258.595398,600.606812 
M599.030640,605.442383 
	C599.311035,610.463928 601.078552,614.528381 606.325684,616.075134 
	C612.517273,617.900208 618.494690,614.562317 619.910400,608.627930 
	C621.652222,601.326599 618.232361,594.999023 611.950195,593.899536 
	C604.658020,592.623291 600.090149,596.413940 599.030640,605.442383 
M575.729248,609.384521 
	C578.771729,615.667114 582.180359,617.443420 588.845947,616.375610 
	C593.344971,615.654907 595.789490,613.169189 596.436523,607.311462 
	C593.432800,608.114441 591.154541,608.841064 588.823914,609.305542 
	C587.250671,609.619080 585.603821,609.563049 583.989807,609.672424 
	C583.832581,606.838928 583.470947,603.985657 583.740234,601.193298 
	C583.780334,600.778015 586.818176,600.329529 588.421997,600.462891 
	C590.842041,600.664001 593.220642,601.363892 596.282043,601.987305 
	C595.230164,596.703796 592.973328,594.713806 589.306946,593.987793 
	C579.722229,592.089966 574.218506,597.816650 575.729248,609.384521 
M194.774307,605.231750 
	C194.774307,608.789307 194.774307,612.346863 194.774307,615.714111 
	C197.552933,615.714111 199.615387,615.714111 202.102768,615.714111 
	C202.102768,612.071655 201.807770,608.740112 202.222687,605.499512 
	C202.442230,603.784790 203.622452,601.107971 204.844727,600.808716 
	C208.610916,599.886597 208.652924,597.441711 208.833817,594.318237 
	C204.123642,594.318237 199.711411,594.318237 194.774048,594.318237 
	C194.774048,597.760559 194.774048,601.038391 194.774307,605.231750 
M210.003876,609.401184 
	C210.003876,611.502075 210.003876,613.602966 210.003876,615.619934 
	C217.758453,616.507690 217.885605,616.426941 217.878555,609.755249 
	C217.874237,605.666870 217.766724,601.578491 217.701828,597.490234 
	C217.658920,594.786255 216.151169,593.361755 213.660187,594.157043 
	C212.228760,594.614075 210.318436,596.196106 210.147430,597.475403 
	C209.666840,601.070374 209.976212,604.770874 210.003876,609.401184 
M564.280273,613.938965 
	C566.609802,614.952515 568.939331,615.966003 571.948853,617.275452 
	C571.948853,613.189026 571.948853,610.626648 571.948853,607.787964 
	C569.332458,607.787964 566.979492,607.787964 564.277527,607.787964 
	C564.277527,609.840698 564.277527,611.479309 564.280273,613.938965 
M438.462769,587.142639 
	C436.173126,586.544983 433.883484,585.947388 430.940399,585.179138 
	C431.115112,588.037964 431.218201,589.724915 431.339935,591.717407 
	C434.586151,591.201294 438.887543,594.037903 438.462769,587.142639 
M214.454422,585.821655 
	C213.022858,585.956116 211.591293,586.090515 210.219620,586.219360 
	C209.950943,592.369751 209.950943,592.369751 219.078049,590.027832 
	C217.572601,588.330444 216.459274,587.075134 214.454422,585.821655 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M460.971405,277.410553 
	C480.945831,278.235077 499.163727,284.534485 515.454895,295.907684 
	C545.407043,316.817932 561.221863,346.646851 567.821899,381.785339 
	C571.434509,401.018677 573.536560,420.374634 565.784119,439.333557 
	C559.775757,454.027283 551.005066,466.881042 539.970276,478.156555 
	C519.369202,499.207123 495.453583,514.492798 465.903625,519.609436 
	C462.957001,520.119690 459.963287,520.638855 456.988281,520.657959 
	C441.003876,520.760681 425.018494,520.706421 408.576538,520.706421 
	C408.576538,472.962036 408.576538,425.282166 408.576538,377.267212 
	C426.975189,377.267212 445.390472,377.267212 464.619934,377.267212 
	C464.619934,407.814484 464.619934,438.447144 464.619934,469.079773 
	C465.124878,469.398865 465.629822,469.717957 466.134796,470.037048 
	C474.015625,464.106445 482.227203,458.563293 489.714874,452.172089 
	C511.575836,433.512329 519.160156,409.088715 512.423157,381.987549 
	C505.617737,354.611023 486.910767,336.605469 459.400360,328.744415 
	C454.833588,327.439484 449.877411,327.034180 445.097595,327.012756 
	C411.779388,326.863312 378.460236,326.924561 345.141388,326.918365 
	C343.489532,326.918060 341.837677,326.918335 340.437988,326.918335 
	C339.974213,326.359772 339.582489,326.108368 339.581238,325.855011 
	C339.502472,310.037964 339.449677,294.220825 339.439941,278.403625 
	C339.439697,278.008636 339.947601,277.613342 340.733826,277.610718 
	C342.623901,278.330627 343.998291,278.941895 345.373505,278.943909 
	C382.503967,278.997589 419.634521,279.006165 456.764862,278.923981 
	C458.168152,278.920868 459.569275,277.937256 460.971405,277.410553 
z"/>
<path fill="#DA2A21" opacity="1.000000" stroke="none" 
	d="
M395.761047,518.583008 
	C394.933990,519.662842 394.141174,520.820190 393.341705,520.824829 
	C375.569092,520.927856 357.795715,520.903687 339.669739,520.903687 
	C339.669739,489.594055 339.669739,458.712738 339.669739,427.373413 
	C358.139160,427.373413 376.637299,427.373413 395.792145,427.373413 
	C395.792145,457.667358 395.792145,487.895416 395.761047,518.583008 
z"/>
<path fill="#DA2A21" opacity="1.000000" stroke="none" 
	d="
M278.967468,464.881348 
	C294.395142,464.731262 309.822784,464.578033 325.250519,464.434296 
	C327.701843,464.411438 330.153564,464.431122 332.863708,464.431122 
	C332.863708,483.431610 332.863708,501.815491 332.863708,520.553711 
	C314.408386,520.553711 296.061310,520.553711 276.953735,520.553711 
	C276.953735,518.389099 276.953735,516.148254 277.324463,513.740112 
	C277.936584,515.014587 278.177979,516.456299 278.419403,517.898010 
	C278.602081,517.863403 278.784790,517.828857 278.967468,517.794250 
	C278.967468,500.156616 278.967468,482.518982 278.967468,464.881348 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M221.174133,579.418213 
	C221.042603,564.018555 221.042603,549.054932 221.042603,533.451233 
	C224.551895,533.451233 227.843033,533.225525 231.071304,533.577087 
	C232.161407,533.695801 233.384109,535.103760 234.048172,536.219055 
	C238.180801,543.160095 242.171967,550.185303 247.152832,557.024292 
	C247.152832,549.419189 247.152832,541.814026 247.152832,533.820679 
	C251.292740,533.820679 254.832581,533.820679 258.708374,533.820679 
	C258.708374,549.152405 258.708374,564.543030 258.708374,580.559448 
	C256.317261,580.559448 253.764496,580.996887 251.458252,580.430542 
	C249.352768,579.913574 246.779770,578.785095 245.688202,577.099976 
	C241.465134,570.580627 237.751801,563.731018 233.053421,555.634033 
	C233.053421,564.669678 233.053421,572.264526 233.053421,580.703796 
	C228.709213,580.389648 225.007446,580.121948 221.174133,579.418213 
z"/>
<path fill="#161718" opacity="1.000000" stroke="none" 
	d="
M188.260742,564.630249 
	C190.707031,569.874207 192.965622,574.788879 195.605026,580.532166 
	C190.934586,580.532166 187.160034,580.736755 183.433319,580.404602 
	C182.348511,580.307861 181.042435,578.862183 180.454315,577.717957 
	C178.408646,573.737854 176.811157,569.522095 174.675766,565.595764 
	C173.704239,563.809387 171.872299,562.490967 170.430649,560.960266 
	C170.021835,561.197815 169.613022,561.435364 169.204208,561.672913 
	C169.204208,567.714905 169.204208,573.756958 169.204208,580.180298 
	C164.858597,580.180298 160.973404,580.180298 156.750244,580.180298 
	C156.750244,564.762329 156.750244,549.402527 156.750244,534.093201 
	C165.969177,534.093201 175.067688,533.714417 184.110718,534.258301 
	C188.418839,534.517456 190.990814,538.211731 191.767334,542.431152 
	C192.797897,548.030945 192.490372,553.451904 187.539230,557.436890 
	C186.543732,558.238098 185.415466,558.874451 184.307846,559.613953 
	C185.643799,561.276978 186.858398,562.788940 188.260742,564.630249 
M169.679474,552.419250 
	C171.806839,552.315125 174.227402,552.803162 175.977356,551.936768 
	C177.655609,551.105896 179.732330,548.788391 179.619431,547.274475 
	C179.506088,545.754517 177.124741,543.865540 175.334610,543.159607 
	C173.538635,542.451416 171.245529,543.003967 169.039078,543.003967 
	C169.039078,546.307617 169.039078,549.074341 169.679474,552.419250 
z"/>
<path fill="#101012" opacity="1.000000" stroke="none" 
	d="
M571.211548,575.228149 
	C567.755432,577.055359 564.655273,579.642517 561.324646,579.984192 
	C554.453857,580.689270 547.460144,580.196472 540.239990,580.196472 
	C540.239990,564.677917 540.239990,549.302124 540.239990,533.855225 
	C547.773376,533.855225 555.057129,533.602966 562.313293,533.942017 
	C567.323181,534.176208 571.230286,537.129639 573.095215,541.674988 
	C577.715149,552.935059 578.551636,564.196655 571.211548,575.228149 
M557.048218,569.844238 
	C561.805420,569.456909 563.524841,566.285400 563.854736,562.106506 
	C564.089722,559.129639 564.164978,556.107361 563.940002,553.133423 
	C563.393188,545.906738 560.204590,543.535339 552.111816,544.497742 
	C552.111816,551.708679 552.294373,559.000977 552.031738,566.277283 
	C551.907837,569.709167 553.440552,570.266907 557.048218,569.844238 
z"/>
<path fill="#161718" opacity="1.000000" stroke="none" 
	d="
M380.535217,579.381958 
	C373.659058,580.053772 366.907959,580.365601 360.153351,580.546692 
	C356.025665,580.657349 351.892639,580.567200 347.440155,580.567200 
	C347.440155,564.765137 347.440155,549.396606 347.440155,533.718140 
	C358.244995,533.718140 368.960449,533.718140 379.878418,533.718140 
	C379.878418,536.914612 379.878418,539.871155 379.878418,543.359131 
	C373.320343,543.359131 366.772003,543.359131 359.890656,543.359131 
	C359.890656,546.072388 359.890656,548.264526 359.890656,550.844666 
	C366.008636,550.844666 371.943665,550.844666 378.219208,550.844666 
	C378.219208,554.279785 378.219208,557.338318 378.219208,560.733948 
	C372.017731,560.733948 366.099274,560.733948 359.868347,560.733948 
	C359.868347,563.782227 359.868347,566.503906 359.868347,569.711060 
	C366.674561,569.711060 373.398224,569.711060 380.658752,569.711060 
	C380.658752,573.100281 380.658752,576.033569 380.535217,579.381958 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M288.843262,533.100342 
	C296.513031,535.878418 298.893646,538.982971 299.198761,546.702698 
	C295.625793,546.932678 292.034698,547.163757 288.443604,547.394897 
	C288.666534,546.970093 288.889435,546.545288 289.112366,546.120483 
	C286.865387,544.582031 284.770203,542.639954 282.294739,541.708862 
	C281.339508,541.349548 279.520813,543.285461 278.095520,544.175659 
	C279.004242,545.526855 279.618134,547.530457 280.879883,548.100830 
	C284.026886,549.523315 287.533234,550.124146 290.778839,551.360596 
	C298.657532,554.362122 302.121033,560.274536 300.819214,568.112061 
	C299.530396,575.871399 293.733063,580.816345 285.544891,581.140442 
	C272.930115,581.639771 267.220551,577.851196 265.236023,567.617615 
	C265.085938,566.843628 265.216278,566.015198 265.216278,564.979370 
	C269.015137,564.691040 272.613983,564.417908 276.249664,564.141907 
	C277.023041,566.022827 277.251526,568.316772 278.480652,569.171082 
	C280.454987,570.543335 283.097137,571.561279 285.444702,571.536865 
	C286.635834,571.524536 289.027710,568.518555 288.720581,567.548767 
	C288.081238,565.530151 286.329803,563.373596 284.449463,562.347656 
	C281.280304,560.618591 277.531219,559.985657 274.249115,558.423096 
	C268.323792,555.602173 265.837891,550.782776 266.665710,544.651550 
	C267.494843,538.510559 271.604370,534.086670 278.005188,533.168091 
	C281.432037,532.676392 284.977386,533.010498 288.843262,533.100342 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M457.781433,549.483643 
	C458.641205,546.719543 459.351074,544.331909 460.104370,541.958069 
	C462.947876,532.997925 464.847595,531.815979 474.236847,533.549500 
	C475.248260,533.736206 476.578796,534.828735 476.889099,535.784241 
	C481.634674,550.399780 486.235077,565.062439 491.116028,580.494141 
	C487.044952,580.494141 483.585602,580.821472 480.252777,580.315430 
	C479.095612,580.139648 477.592133,577.990479 477.397827,576.583618 
	C476.947723,573.324585 475.346710,572.488831 472.376129,572.753052 
	C470.067871,572.958374 467.659027,572.461304 465.425232,572.916321 
	C464.067200,573.193054 462.055542,574.553650 461.894714,575.654785 
	C461.272491,579.914734 458.984222,581.048035 455.089508,580.596558 
	C452.977875,580.351807 450.814514,580.552917 447.935791,580.552917 
	C451.286865,569.968811 454.469208,559.917603 457.781433,549.483643 
M465.845795,559.242554 
	C465.617676,560.157959 465.389557,561.073364 465.068085,562.363342 
	C467.912933,562.363342 470.464874,562.363342 473.636047,562.363342 
	C472.314789,557.022522 471.075470,552.013123 469.474701,545.542480 
	C468.052246,550.817200 467.024292,554.629028 465.845795,559.242554 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M589.075806,540.827515 
	C590.276062,538.129089 590.929321,534.257935 592.537720,533.809082 
	C596.077942,532.821045 600.106384,533.464966 603.919983,533.697693 
	C604.502625,533.733215 605.234680,535.054810 605.505737,535.906311 
	C610.093079,550.314331 614.621094,564.741272 619.136353,579.172119 
	C619.217407,579.431274 618.981873,579.789551 618.766663,580.554504 
	C616.603394,580.554504 614.289429,580.346436 612.027710,580.601685 
	C608.316467,581.020569 606.036133,580.084839 605.532043,575.911804 
	C605.394531,574.774109 603.721069,573.049438 602.620117,572.941162 
	C598.851624,572.570618 595.024231,572.799072 591.376221,572.799072 
	C588.185059,581.443298 588.185059,581.443298 576.634705,580.058594 
	C580.767700,567.032898 584.864929,554.119995 589.075806,540.827515 
M601.724854,562.278870 
	C600.689758,557.315430 599.654663,552.351990 598.619568,547.388550 
	C598.193481,547.460632 597.767395,547.532715 597.341370,547.604736 
	C596.125488,552.456665 594.909668,557.308533 593.573669,562.640076 
	C596.698547,562.640076 598.828064,562.640076 601.724854,562.278870 
z"/>
<path fill="#121314" opacity="1.000000" stroke="none" 
	d="
M665.429138,538.211975 
	C666.013489,534.121094 668.125427,532.963257 671.741089,533.380676 
	C674.525269,533.702148 677.376282,533.444763 680.363831,533.444763 
	C685.286133,548.959595 690.132996,564.236816 695.158447,580.076904 
	C690.799561,580.076904 686.807617,580.076904 682.441650,580.076904 
	C681.844360,577.915466 681.200073,575.583801 680.491089,573.017944 
	C675.909302,573.017944 671.319580,573.017944 666.565613,573.017944 
	C665.880005,575.566895 665.248291,577.915466 664.631287,580.209473 
	C660.632812,580.209473 656.729492,580.209473 652.202087,580.209473 
	C656.666931,566.014160 660.980896,552.298279 665.429138,538.211975 
M674.779785,550.114075 
	C674.424133,549.035339 674.068420,547.956604 673.423950,546.002258 
	C671.895081,551.854675 670.589661,556.851624 669.180969,562.244141 
	C672.330139,562.244141 674.933411,562.244141 677.853149,562.244141 
	C676.812378,558.107788 675.896729,554.468811 674.779785,550.114075 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M410.550262,545.258667 
	C404.239075,542.408875 400.241669,543.902161 399.007141,550.110535 
	C398.152191,554.409607 398.378815,559.021729 398.761200,563.438660 
	C399.077942,567.096924 400.869141,570.022888 405.303497,570.262939 
	C409.626007,570.497009 411.595276,567.995483 412.848145,564.420959 
	C413.162018,563.525391 413.480530,562.631348 413.889618,561.474487 
	C417.401031,562.689880 420.645752,563.812927 423.761932,564.891479 
	C424.458557,571.023743 418.372040,578.837219 413.145813,580.399597 
	C405.426849,582.707153 396.361633,580.990051 391.880310,575.758606 
	C384.319794,566.932617 383.524475,549.051819 390.457977,539.738647 
	C395.717987,532.673279 403.215942,531.835571 411.119110,533.270874 
	C417.927368,534.507263 422.142761,539.530579 423.489105,547.373230 
	C420.371338,548.230713 417.219269,549.097656 413.822479,550.031860 
	C412.809906,548.469238 411.844391,546.979187 410.550262,545.258667 
z"/>
<path fill="#121314" opacity="1.000000" stroke="none" 
	d="
M307.065155,560.999573 
	C307.065186,551.702759 307.065186,542.905823 307.065186,534.011780 
	C315.603882,534.011780 323.903290,533.382385 332.053894,534.213013 
	C338.452271,534.865112 341.470123,541.603333 340.682098,550.497620 
	C340.040649,557.737183 335.309296,562.547974 328.201324,562.998047 
	C325.439087,563.172913 322.656555,563.026672 319.470825,563.026672 
	C319.470825,569.048706 319.470825,574.468567 319.470825,580.211304 
	C315.320343,580.211304 311.583344,580.211304 307.065125,580.211304 
	C307.065125,573.968506 307.065125,567.733948 307.065155,560.999573 
M322.364227,553.586792 
	C326.286224,553.432922 329.534302,552.135193 328.813171,547.519104 
	C327.990234,542.251099 323.766479,542.872559 319.254333,543.185852 
	C320.548798,546.704956 316.769806,550.832581 322.364227,553.586792 
z"/>
<path fill="#131415" opacity="1.000000" stroke="none" 
	d="
M618.208374,545.019897 
	C617.394836,541.308472 617.061462,537.630005 616.701965,533.664185 
	C629.444031,533.664185 641.519775,533.664185 653.934326,533.664185 
	C653.934326,537.267822 653.934326,540.844482 653.934326,544.931885 
	C650.155151,544.931885 646.272278,544.931885 641.885315,544.931885 
	C641.885315,556.953125 641.885315,568.483337 641.885315,580.278992 
	C637.623657,580.278992 633.887146,580.278992 629.595215,580.278992 
	C629.595215,568.545227 629.595215,557.008423 629.595215,545.052856 
	C625.597412,545.052856 622.142944,545.052856 618.208374,545.019897 
z"/>
<path fill="#121314" opacity="1.000000" stroke="none" 
	d="
M510.001740,580.547119 
	C504.862701,580.546082 500.220856,580.546082 495.293823,580.546082 
	C495.293823,564.742920 495.293823,549.351562 495.293823,533.696045 
	C499.173431,533.696045 502.937378,533.696045 507.142090,533.696045 
	C507.142090,545.363647 507.142090,556.967896 507.142090,568.915649 
	C513.693298,568.915649 519.819336,568.915649 526.129028,568.915649 
	C526.129028,572.850952 526.129028,576.433838 526.129028,580.548218 
	C520.737732,580.548218 515.618347,580.548218 510.001740,580.547119 
z"/>
<path fill="#101012" opacity="1.000000" stroke="none" 
	d="
M443.094086,557.999268 
	C443.094086,565.626831 443.094086,572.754639 443.094086,580.216797 
	C439.057556,580.216797 435.320862,580.216797 431.219391,580.216797 
	C431.219391,564.790771 431.219391,549.425415 431.219391,533.747070 
	C434.932770,533.747070 438.663452,533.747070 443.094086,533.747070 
	C443.094086,541.677185 443.094086,549.588318 443.094086,557.999268 
z"/>
<path fill="#101012" opacity="1.000000" stroke="none" 
	d="
M200.071640,534.493408 
	C203.938736,533.773254 207.679779,533.494568 211.796478,533.187866 
	C211.796478,549.120544 211.796478,564.501526 211.796478,580.213135 
	C208.052109,580.213135 204.312057,580.213135 199.945587,580.213135 
	C199.945587,565.075195 199.945587,550.005005 200.071640,534.493408 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M490.202820,586.953918 
	C490.352631,586.701111 490.530731,586.470032 491.297607,586.121826 
	C493.811340,586.174438 495.764618,586.365845 497.717865,586.557251 
	C497.717865,596.127625 497.717865,605.698059 497.717865,616.228516 
	C492.676910,616.228516 487.909210,616.546448 483.224518,616.061768 
	C481.507812,615.884155 479.987885,613.804382 478.295441,611.833618 
	C478.482880,606.390442 478.753052,601.706055 479.023254,597.021606 
	C479.023224,597.021606 479.000000,597.000000 479.332245,596.847473 
	C483.039551,595.625183 486.414612,594.555420 490.688507,593.200806 
	C490.602783,592.098145 490.402802,589.526062 490.202820,586.953918 
M484.866302,608.090759 
	C487.265564,611.471497 489.394043,610.835632 490.164673,607.193237 
	C490.589508,605.185059 489.598145,602.818909 488.955139,600.702271 
	C488.851196,600.360229 486.524231,600.186462 486.422699,600.431396 
	C485.649994,602.295471 485.160126,604.278870 484.623657,606.236511 
	C484.541931,606.534668 484.674713,606.891479 484.866302,608.090759 
z"/>
<path fill="#CD2E26" opacity="1.000000" stroke="none" 
	d="
M379.603455,602.960449 
	C379.454163,606.051880 379.341217,608.966553 378.561615,611.690369 
	C377.229828,616.343384 374.900146,617.071228 367.330597,615.568176 
	C367.173370,618.344543 367.018585,621.077576 366.848114,624.087830 
	C364.667053,624.087830 362.594849,624.087830 360.292023,624.087830 
	C360.165039,622.945496 359.970062,621.994690 359.967468,621.043335 
	C359.943848,612.280518 359.953552,603.517639 359.953552,594.594055 
	C364.829742,594.355530 369.387177,593.587708 373.810333,594.064758 
	C378.516541,594.572388 378.461456,599.168091 379.603455,602.960449 
M372.952301,606.281677 
	C372.957336,605.621582 373.177185,604.861938 372.926178,604.320557 
	C372.241913,602.844727 371.358795,601.461121 370.554443,600.040894 
	C369.389984,601.154297 367.248352,602.284607 367.267334,603.377441 
	C367.305237,605.560425 368.527802,607.722839 369.261108,609.893738 
	C370.476379,608.968384 371.691620,608.043030 372.952301,606.281677 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M258.315002,600.391846 
	C258.319550,598.959351 258.215363,597.380066 258.954590,596.584839 
	C262.802887,592.445129 267.819550,593.405640 272.497284,594.285522 
	C276.507263,595.039856 277.192291,598.567993 277.240204,602.073853 
	C277.301117,606.532776 277.254974,610.993103 277.254974,615.378601 
	C272.550873,615.759521 267.797913,616.692322 263.165924,616.276672 
	C261.009949,616.083313 258.145538,613.661011 257.281738,611.558960 
	C255.979919,608.390930 257.505676,605.379639 261.228302,604.194580 
	C264.138763,603.268127 267.044769,602.327637 269.952789,601.393372 
	C269.865509,600.762634 269.778229,600.131897 269.690979,599.501221 
	C265.992432,599.869751 262.293915,600.238281 258.315002,600.391846 
M267.799255,606.500549 
	C267.174286,606.706421 266.327515,606.727600 265.970276,607.156494 
	C265.212311,608.066406 264.709320,609.188721 264.101807,610.223999 
	C265.021851,610.762268 266.283447,611.969727 266.795288,611.708435 
	C268.118042,611.033203 269.237732,609.801392 270.124237,608.566284 
	C270.298340,608.323792 269.059845,607.067444 267.799255,606.500549 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M398.742065,615.831238 
	C397.272430,616.068481 395.810852,616.413086 394.331757,616.523621 
	C388.933289,616.926880 384.903046,614.557922 383.219940,610.088196 
	C381.463379,605.423340 383.243683,598.156372 386.797424,595.485779 
	C390.291626,592.859924 397.159058,593.016235 400.711395,596.161194 
	C401.920258,597.231445 402.366058,599.163513 403.405029,601.396240 
	C403.649506,603.540039 403.649506,604.989441 403.649506,607.060852 
	C399.060150,607.060852 394.680664,607.060852 389.954803,607.060852 
	C390.542725,610.773560 391.927551,611.917725 395.136169,611.130432 
	C397.626343,610.519470 400.264893,610.513245 402.837494,610.238220 
	C402.826752,610.803955 402.815979,611.369751 402.428833,612.352600 
	C400.948975,613.790222 399.845520,614.810730 398.742065,615.831238 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M442.953217,614.117615 
	C442.953217,614.117615 442.822601,613.818298 442.641724,613.327881 
	C442.398132,610.951355 442.335419,609.065369 442.443817,607.009033 
	C442.810059,606.573914 442.932068,606.278870 443.238281,605.913452 
	C443.679382,605.594788 443.863220,605.316284 444.024414,605.018555 
	C444.001801,604.999329 444.033905,605.048828 444.301392,605.047791 
	C444.793701,604.787476 445.018494,604.528259 445.254150,604.257202 
	C445.264984,604.245483 445.298309,604.254456 445.690369,604.262451 
	C448.287689,603.702393 450.561615,603.310120 452.667145,602.487122 
	C453.619904,602.114685 454.238159,600.886536 455.008179,600.046631 
	C453.737061,599.680359 452.389923,598.853882 451.211426,599.048584 
	C449.081390,599.400391 447.052063,600.362183 444.710785,600.990967 
	C444.071594,600.852539 443.702332,600.792419 443.278748,600.382812 
	C443.098724,599.339905 442.973022,598.646423 442.847290,597.952942 
	C445.105988,594.178467 450.677643,592.722595 457.197357,594.215027 
	C461.790466,595.266418 462.206604,598.867371 462.297913,602.641357 
	C462.401398,606.917480 462.476501,611.194275 462.024567,615.744995 
	C461.320099,616.070618 461.154938,616.122009 460.989807,616.173462 
	C459.921722,616.129578 458.853607,616.085693 457.230286,615.784241 
	C455.441193,615.635620 454.207336,615.744568 452.973480,615.853455 
	C452.539581,615.908142 452.105652,615.962830 450.961792,616.107300 
	C447.818970,615.504028 445.386108,614.810852 442.953217,614.117615 
M454.933685,609.548950 
	C455.791443,607.562195 456.225677,605.621399 453.001312,606.491943 
	C451.567963,606.878906 450.282349,608.084961 449.171692,609.167542 
	C448.947693,609.385864 449.934601,611.463135 450.160583,611.426697 
	C451.639771,611.188110 453.063263,610.604187 454.933685,609.548950 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M599.040527,605.023438 
	C600.090149,596.413940 604.658020,592.623291 611.950195,593.899536 
	C618.232361,594.999023 621.652222,601.326599 619.910400,608.627930 
	C618.494690,614.562317 612.517273,617.900208 606.325684,616.075134 
	C601.078552,614.528381 599.311035,610.463928 599.040527,605.023438 
M612.692505,601.477295 
	C611.126160,600.999268 608.410339,599.807922 608.189087,600.164551 
	C607.138245,601.858093 606.105225,604.059631 606.346741,605.913452 
	C606.572876,607.648926 608.405945,609.174927 609.531860,610.793152 
	C610.715149,609.270935 612.183960,607.881714 612.977478,606.177979 
	C613.473938,605.112061 612.953674,603.572449 612.692505,601.477295 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M543.157959,595.085571 
	C545.063965,594.629578 546.949646,593.913391 548.879517,593.762390 
	C557.674316,593.074097 560.129211,595.416748 560.272583,604.236023 
	C560.334106,608.017151 560.514526,611.796326 560.066467,615.816284 
	C559.328369,616.101624 559.164795,616.147034 559.001160,616.192444 
	C557.930237,616.133240 556.859375,616.073975 555.135925,615.826416 
	C553.770081,615.304016 553.022827,614.627686 552.349365,614.694580 
	C549.227905,615.004395 546.126892,615.520569 543.018555,615.962830 
	C542.629761,615.632812 542.241028,615.302734 541.518921,614.517944 
	C539.020569,608.044373 540.087952,605.671570 545.843689,603.831909 
	C547.110352,603.427002 548.473816,603.290405 549.697144,602.794434 
	C550.847534,602.328003 551.920532,601.612427 552.901489,600.845154 
	C553.057251,600.723267 552.553894,599.255676 552.346191,599.253479 
	C550.934937,599.238281 549.509338,599.376099 548.111023,599.603760 
	C545.852966,599.971558 543.612427,600.446472 541.364441,600.876099 
	C541.156738,600.328003 540.949036,599.779846 540.741333,599.231689 
	C541.546875,597.849670 542.352417,596.467651 543.157959,595.085571 
M551.730408,606.214294 
	C550.944763,606.463806 549.997803,606.519287 549.410645,607.007507 
	C548.491333,607.772034 547.600159,608.740417 547.158325,609.812561 
	C547.012939,610.165405 548.419617,611.749146 548.903931,611.656677 
	C550.229980,611.403381 551.825806,610.846924 552.575623,609.852905 
	C553.143005,609.100769 552.573425,607.490845 551.730408,606.214294 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M316.023499,600.963562 
	C316.023499,600.963562 315.689270,600.792908 315.408264,600.439941 
	C315.754547,598.392151 316.381805,596.697327 317.009094,595.002502 
	C317.804108,594.703186 318.599121,594.403870 320.131470,594.056152 
	C322.917572,593.906982 324.966400,593.806274 327.015198,593.705444 
	C327.696442,593.830383 328.377655,593.955322 329.545471,594.342407 
	C330.364899,594.671997 330.697693,594.739380 331.030518,594.806702 
	C331.566467,595.389282 332.102417,595.971863 332.852478,597.151245 
	C333.436676,599.511597 333.806763,601.275269 334.128052,603.450439 
	C334.113586,605.241394 334.147888,606.620789 334.070129,608.432373 
	C333.987854,611.240417 334.017609,613.616211 334.047363,615.992065 
	C329.705688,616.154358 325.360107,616.248474 321.023285,616.496155 
	C315.647522,616.803101 313.462341,614.147461 314.289032,607.358887 
	C314.618927,606.567200 314.704926,606.252930 314.790894,605.938660 
	C314.790894,605.938660 314.939056,605.925842 315.175446,605.830566 
	C315.740387,605.587769 315.921509,605.334656 315.955231,604.976013 
	C315.955231,604.976013 315.990936,605.006531 316.358887,604.935303 
	C320.254913,603.687073 323.782990,602.510010 327.311066,601.333008 
	C327.178192,600.625549 327.045288,599.918091 326.912384,599.210632 
	C325.362091,599.210632 323.773102,598.994019 322.270844,599.262695 
	C320.154694,599.641113 318.102844,600.379211 316.023499,600.963562 
M323.409760,611.856812 
	C324.833618,610.422180 326.257507,608.987610 327.681366,607.552979 
	C327.189026,606.987122 326.696655,606.421265 326.204315,605.855408 
	C324.489868,606.809143 322.726471,607.695312 321.148804,608.837891 
	C321.014587,608.935059 322.119019,610.742615 323.409760,611.856812 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M522.201477,614.974976 
	C516.466614,615.329651 510.664276,616.369751 505.019196,615.813599 
	C500.802887,615.398254 500.117767,611.127319 503.084045,605.383545 
	C503.808319,604.832703 504.048035,604.571167 504.287781,604.309570 
	C504.287811,604.309631 504.320923,604.303223 504.703918,604.295105 
	C508.212952,603.357910 511.338959,602.428772 514.464966,601.499695 
	C514.380737,600.834229 514.296509,600.168762 514.212280,599.503296 
	C512.728638,599.431458 511.202118,599.106689 509.771820,599.349426 
	C507.808960,599.682312 505.919098,600.445374 503.997955,601.024109 
	C501.189148,600.527771 500.138977,599.266235 502.608551,597.074707 
	C503.989014,595.849670 505.735626,595.037292 508.102844,593.979736 
	C519.990784,593.784485 520.654480,594.416443 521.287598,605.659241 
	C521.314636,606.139221 521.497925,606.610352 521.608521,607.085632 
	C521.546326,607.494934 521.484192,607.904175 521.363708,608.986450 
	C521.604065,611.431213 521.902771,613.203125 522.201477,614.974976 
M513.337158,606.115601 
	C512.550110,606.376465 511.608795,606.449036 511.009979,606.939697 
	C509.960724,607.799438 509.025024,608.853821 508.297119,609.990295 
	C508.186310,610.163208 509.591644,611.845032 510.045654,611.740662 
	C511.373535,611.435547 512.919434,610.854126 513.715454,609.852783 
	C514.336609,609.071472 513.964600,607.500488 513.337158,606.115601 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M575.468018,609.067627 
	C574.218506,597.816650 579.722229,592.089966 589.306946,593.987793 
	C592.973328,594.713806 595.230164,596.703796 596.282043,601.987305 
	C593.220642,601.363892 590.842041,600.664001 588.421997,600.462891 
	C586.818176,600.329529 583.780334,600.778015 583.740234,601.193298 
	C583.470947,603.985657 583.832581,606.838928 583.989868,609.672363 
	C585.603821,609.563049 587.250671,609.619080 588.823914,609.305542 
	C591.154541,608.841064 593.432800,608.114441 596.436523,607.311462 
	C595.789490,613.169189 593.344971,615.654907 588.845947,616.375610 
	C582.180359,617.443420 578.771729,615.667114 575.468018,609.067627 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M340.197998,595.081177 
	C344.241455,594.865173 348.315063,594.295898 352.311005,594.636841 
	C353.601746,594.746948 354.688446,597.248657 356.345367,599.225281 
	C353.316376,599.841736 351.330353,600.245972 348.108734,600.901611 
	C350.432159,602.135864 351.976440,602.492493 352.745056,603.463257 
	C354.239929,605.351379 356.507050,607.762695 356.230927,609.628174 
	C355.893677,611.906921 353.559235,615.219543 351.573853,615.636902 
	C347.902924,616.408630 343.857483,615.398865 339.554382,614.655762 
	C338.491821,612.844971 337.842957,611.509644 337.403442,610.095886 
	C337.612793,610.017456 337.995789,609.788513 338.373383,609.831482 
	C340.937286,610.386597 343.101959,611.103210 345.317627,611.337524 
	C346.744141,611.488342 348.245544,610.930664 349.713409,610.690552 
	C348.906158,609.716919 348.286926,608.295471 347.253143,607.861572 
	C344.999817,606.915649 342.535492,606.472595 340.075012,605.885132 
	C339.992615,605.946228 340.172424,605.848022 340.085846,605.596558 
	C339.689270,605.197632 339.379272,605.050110 339.059875,604.917542 
	C339.050507,604.932495 339.088593,604.913452 339.020142,604.540039 
	C338.565338,602.436646 338.178986,600.706726 337.792603,598.976746 
	C337.906311,598.606384 338.020020,598.235962 338.387909,597.439148 
	C338.787109,596.692139 338.932190,596.371582 339.077209,596.051025 
	C339.077209,596.051025 339.009521,596.001953 339.303345,595.988281 
	C339.797455,595.676758 339.997742,595.378967 340.197998,595.081177 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M309.186340,607.070312 
	C308.635529,608.810181 307.932373,610.518982 307.570007,612.297302 
	C307.041931,614.889160 306.086151,616.430603 302.974426,616.219482 
	C300.168152,616.029175 298.174377,615.680481 297.920349,612.250671 
	C297.812134,610.789734 296.703339,609.403015 294.869446,608.066589 
	C294.558807,609.372620 294.333405,610.706848 293.914276,611.977051 
	C293.467041,613.332581 292.836823,614.627747 292.277222,615.971436 
	C290.162994,615.971436 288.196167,615.971436 285.953094,615.971436 
	C283.790802,608.973389 281.647736,602.037476 279.504669,595.101562 
	C279.761810,594.601196 280.018982,594.100830 280.276154,593.600464 
	C282.468323,594.417664 285.511261,594.625305 286.641846,596.203186 
	C288.125336,598.273499 288.101166,601.424072 289.845459,604.164673 
	C290.371704,602.368652 290.828857,600.548645 291.441742,598.782593 
	C292.204742,596.584106 292.052155,593.932190 295.829010,593.875000 
	C299.326324,593.822083 299.870361,595.856323 300.594391,598.268555 
	C300.957397,599.477905 301.448364,600.648804 303.046570,602.046631 
	C303.962555,599.645874 304.878540,597.245117 306.084137,594.085388 
	C307.774597,594.188293 310.163635,594.333740 312.506348,595.076111 
	C311.368774,599.472107 310.277527,603.271240 309.186340,607.070312 
M299.432373,609.017395 
	C300.584503,610.660278 301.736633,612.303101 302.888824,613.945984 
	C304.149445,612.211975 305.690979,610.612061 306.599152,608.709717 
	C307.716766,606.368774 308.398987,603.801147 309.089630,601.282104 
	C309.512085,599.741394 309.596771,598.108093 309.833160,596.516357 
	C309.225891,596.359375 308.618622,596.202393 308.011353,596.045410 
	C306.701508,599.881531 305.391663,603.717651 304.081818,607.553711 
	C303.616425,607.639038 303.151001,607.724365 302.685608,607.809631 
	C301.541992,605.283508 300.547302,602.674255 299.201202,600.261047 
	C298.336639,598.711121 296.954742,597.449829 295.804718,596.059143 
	C294.853333,597.642273 293.719147,599.146729 292.994995,600.827759 
	C292.002625,603.131531 291.321472,605.569336 290.506958,607.949707 
	C289.823608,607.952454 289.140259,607.955139 288.456909,607.957825 
	C287.640259,604.937378 286.952789,601.872314 285.935303,598.921082 
	C285.589722,597.918701 284.411926,597.203186 283.614410,596.356628 
	C283.310669,597.454529 282.546631,598.666199 282.784882,599.630127 
	C283.696869,603.320007 284.750580,607.001099 286.137634,610.533813 
	C286.660858,611.866455 288.233643,612.787048 289.327972,613.895447 
	C290.224823,612.842834 291.344177,611.907288 291.972198,610.713257 
	C293.291016,608.205994 294.351715,605.562927 295.518341,602.975586 
	C296.052429,603.012085 296.586517,603.048584 297.120575,603.085083 
	C297.802338,604.818420 298.484100,606.551819 299.432373,609.017395 
z"/>
<path fill="#CD3730" opacity="1.000000" stroke="none" 
	d="
M410.972198,594.991699 
	C411.777252,594.689697 412.582306,594.387695 414.116577,594.029114 
	C416.567505,593.906006 418.289154,593.839478 420.010834,593.772949 
	C423.489624,594.828491 426.438721,596.410278 427.152405,602.114868 
	C424.147491,601.437683 421.784637,600.663635 419.371887,600.447937 
	C417.813354,600.308716 414.888031,600.785889 414.802765,601.333496 
	C414.394592,603.955750 414.574036,606.726929 415.040527,609.357300 
	C415.100800,609.697327 418.104980,609.832153 419.635468,609.520935 
	C421.965607,609.047119 424.199585,608.100403 427.068298,607.159790 
	C427.226929,613.046082 424.483063,615.258423 420.495148,616.275330 
	C414.826813,617.720642 409.545837,615.420959 407.075562,609.887085 
	C406.878632,606.367310 406.779083,603.639893 406.679565,600.912537 
	C406.843048,600.576172 407.006500,600.239868 407.460022,599.429321 
	C408.267639,598.033875 408.785187,597.112610 409.302673,596.191345 
	C409.302673,596.191345 409.677002,596.076416 410.079773,595.950378 
	C410.645721,595.546814 410.808960,595.269226 410.972198,594.991699 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M630.674683,606.939392 
	C630.674744,609.734253 630.674744,612.529114 630.674744,615.722778 
	C628.453064,615.722778 626.257568,615.722778 623.839478,615.722778 
	C623.839478,608.578430 623.839478,601.518799 623.839478,594.815796 
	C630.144592,594.815796 636.057190,594.815796 641.969727,594.815796 
	C642.025635,595.290894 642.081482,595.765930 642.137390,596.241028 
	C643.071716,595.628418 643.950378,594.898926 644.949829,594.422913 
	C649.773743,592.125488 654.336670,594.360718 654.769226,599.674133 
	C655.198059,604.942139 654.863037,610.272339 654.863037,615.844788 
	C652.612366,615.844788 650.387146,615.844788 647.429321,615.844788 
	C647.429321,611.134583 647.429321,606.521790 647.698853,601.529053 
	C648.638123,604.398987 649.187195,607.681335 650.046814,610.880188 
	C650.326294,611.920288 651.417358,612.742310 652.137268,613.664062 
	C652.748779,612.810059 653.912537,611.933289 653.878479,611.105957 
	C653.710510,607.025452 653.666260,602.838257 652.611084,598.951233 
	C652.244690,597.601501 649.096802,597.006775 647.219299,596.067261 
	C646.818176,597.393616 646.417053,598.720032 646.015930,600.046387 
	C645.508667,600.223267 645.001404,600.400024 644.225281,600.303955 
	C640.397461,592.181274 633.530151,598.044617 628.479065,596.340759 
	C627.803345,596.112793 626.460754,596.217773 626.234436,596.621399 
	C625.645142,597.672119 625.279663,598.965332 625.229004,600.178650 
	C625.093994,603.409180 624.960449,606.668335 625.258850,609.875793 
	C625.396179,611.351929 626.545044,612.733948 627.590576,614.892273 
	C629.020081,611.205994 629.847412,609.072693 630.674683,606.939392 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M533.793396,587.353333 
	C533.764099,587.715088 533.734802,588.076904 533.580017,589.001831 
	C533.202026,592.140015 532.892639,594.758240 536.896545,594.109314 
	C536.896545,594.109314 536.992859,594.001465 537.019409,594.467896 
	C537.118958,597.286865 537.797729,599.344299 534.480286,600.928833 
	C533.281677,601.501343 533.947510,606.872681 534.513916,609.914368 
	C534.862488,611.786194 536.632507,613.393433 537.764343,615.119446 
	C537.620056,615.254944 537.475769,615.390503 536.719482,615.774658 
	C533.735657,616.022400 531.363770,616.021606 528.991821,616.020752 
	C528.991821,616.020813 528.996399,615.995850 528.907410,615.770020 
	C528.640076,615.207947 528.363586,615.073303 527.988892,615.140320 
	C527.686646,614.612976 527.384460,614.085632 526.981628,612.844543 
	C525.741638,607.770020 528.609558,602.651794 524.194641,598.793945 
	C523.556213,598.236084 524.084412,595.940002 524.551147,594.617065 
	C525.215088,592.735291 526.322083,591.009888 527.239868,589.217712 
	C527.239868,589.217712 527.048035,589.041504 527.485107,589.119690 
	C529.879211,588.583008 531.836304,587.968201 533.793396,587.353333 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M194.774170,604.773926 
	C194.774048,601.038391 194.774048,597.760559 194.774048,594.318237 
	C199.711411,594.318237 204.123642,594.318237 208.833817,594.318237 
	C208.652924,597.441711 208.610916,599.886597 204.844727,600.808716 
	C203.622452,601.107971 202.442230,603.784790 202.222687,605.499512 
	C201.807770,608.740112 202.102768,612.071655 202.102768,615.714111 
	C199.615387,615.714111 197.552933,615.714111 194.774307,615.714111 
	C194.774307,612.346863 194.774307,608.789307 194.774170,604.773926 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M245.901657,597.980591 
	C245.901657,594.138245 245.901657,590.295898 245.901657,586.092529 
	C247.989304,586.092529 250.401840,586.092529 253.159760,586.092529 
	C253.159760,597.632141 253.356857,609.119507 252.924149,620.583069 
	C252.866440,622.111450 249.805099,624.245300 247.789139,624.769348 
	C245.944977,625.248840 242.416443,626.277039 242.420837,621.427185 
	C243.438232,619.944397 244.250137,619.103638 244.987488,618.628174 
	C244.555786,620.368774 244.198654,621.743958 243.835724,623.141479 
	C248.653336,624.176514 251.768829,621.599487 251.973892,616.501038 
	C252.193893,611.031494 252.190948,605.538574 251.922607,600.073669 
	C251.852951,598.655029 250.428986,597.302917 249.627090,595.920227 
	C249.066849,596.134460 248.506622,596.348633 247.946381,596.562866 
	C247.946381,601.496094 248.072525,606.434387 247.874039,611.359619 
	C247.809601,612.958679 246.895721,614.523499 246.280518,615.637939 
	C246.094315,609.441467 245.997986,603.710999 245.901657,597.980591 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M630.741699,606.578735 
	C629.847412,609.072693 629.020081,611.205994 627.590576,614.892273 
	C626.545044,612.733948 625.396179,611.351929 625.258850,609.875793 
	C624.960449,606.668335 625.093994,603.409180 625.229004,600.178650 
	C625.279663,598.965332 625.645142,597.672119 626.234436,596.621399 
	C626.460754,596.217773 627.803345,596.112793 628.479065,596.340759 
	C633.530151,598.044617 640.397461,592.181274 643.961304,600.388489 
	C643.966125,600.745850 643.944824,600.921814 643.541199,600.997070 
	C642.999023,602.386292 642.860474,603.700317 642.721924,605.014282 
	C642.758911,606.080261 642.795898,607.146179 642.582336,608.763672 
	C642.460999,609.874817 642.590271,610.434387 642.719543,610.993958 
	C642.760925,611.730042 642.802368,612.466187 642.360840,613.588318 
	C637.802368,614.746887 636.753235,612.905945 636.866455,609.030090 
	C636.944580,606.354797 635.819031,603.644470 635.231323,600.949768 
	C635.231384,600.949768 635.053833,600.971497 634.969910,600.546387 
	C633.625183,599.982117 632.364319,599.842957 631.103516,599.703857 
	C630.976807,601.143433 630.850037,602.583008 630.723267,604.022583 
	C630.751770,604.754456 630.780212,605.486267 630.741699,606.578735 
z"/>
<path fill="#CD2E26" opacity="1.000000" stroke="none" 
	d="
M222.377045,612.956726 
	C222.245667,607.953064 222.114273,602.949341 222.427658,597.415649 
	C224.926147,596.576782 226.979874,596.267883 229.033615,595.958984 
	C229.206299,595.971924 229.378967,595.984802 230.020721,596.303833 
	C233.569092,596.483276 236.648392,596.356384 239.827560,596.491211 
	C240.275238,597.171387 240.623062,597.590027 240.970901,598.008606 
	C240.970901,598.008606 241.114914,597.936279 241.129547,598.153320 
	C241.144165,598.370361 241.073944,598.855713 241.022781,599.252686 
	C240.668259,601.128784 240.364899,602.607910 240.015564,603.791931 
	C238.647232,604.668091 237.324860,605.839355 235.992920,606.619385 
	C235.603882,604.841431 235.224411,603.454773 234.903397,602.050171 
	C234.961853,602.032349 234.873611,602.116943 234.890671,601.763611 
	C234.393494,601.050964 233.879257,600.691711 233.252594,600.347168 
	C233.140167,600.362000 232.978226,600.203247 232.812698,599.915771 
	C231.781281,600.106079 230.915390,600.583862 230.031311,601.027710 
	C230.013123,600.993652 230.067322,601.049561 229.677887,601.114136 
	C229.193207,604.450256 229.097961,607.721863 229.064163,611.365479 
	C229.079895,612.487854 229.034195,613.238220 228.600845,614.000854 
	C226.267822,613.661011 224.322433,613.308838 222.377045,612.956726 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M209.988983,608.915527 
	C209.976212,604.770874 209.666840,601.070374 210.147430,597.475403 
	C210.318436,596.196106 212.228760,594.614075 213.660187,594.157043 
	C216.151169,593.361755 217.658920,594.786255 217.701828,597.490234 
	C217.766724,601.578491 217.874237,605.666870 217.878555,609.755249 
	C217.885605,616.426941 217.758453,616.507690 210.003876,615.619934 
	C210.003876,613.602966 210.003876,611.502075 209.988983,608.915527 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M538.122986,615.088501 
	C536.632507,613.393433 534.862488,611.786194 534.513916,609.914368 
	C533.947510,606.872681 533.281677,601.501343 534.480286,600.928833 
	C537.797729,599.344299 537.118958,597.286865 536.964478,594.514954 
	C538.703064,594.403015 540.523254,594.710510 542.750732,595.051758 
	C542.352417,596.467651 541.546875,597.849670 540.741333,599.231689 
	C540.949036,599.779846 541.156738,600.328003 541.364441,600.876099 
	C543.612427,600.446472 545.852966,599.971558 548.111023,599.603760 
	C549.509338,599.376099 550.934937,599.238281 552.346191,599.253479 
	C552.553894,599.255676 553.057251,600.723267 552.901489,600.845154 
	C551.920532,601.612427 550.847534,602.328003 549.697144,602.794434 
	C548.473816,603.290405 547.110352,603.427002 545.843689,603.831909 
	C540.087952,605.671570 539.020569,608.044373 541.274719,614.436646 
	C540.403137,614.892639 539.442383,614.975098 538.122986,615.088501 
z"/>
<path fill="#CD352E" opacity="1.000000" stroke="none" 
	d="
M438.506531,614.119629 
	C436.348022,614.954773 434.189545,615.789917 431.353699,616.887146 
	C431.353699,608.618103 431.353699,601.713806 431.353699,594.430115 
	C433.621490,594.430115 435.695801,594.430115 437.974670,595.176758 
	C438.288330,601.988831 438.397430,608.054260 438.506531,614.119629 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M473.680481,590.967285 
	C473.794067,593.545959 473.907623,596.124695 473.973694,599.486389 
	C473.988434,604.187134 474.050629,608.104797 474.112793,612.022522 
	C474.421234,617.890747 470.251434,615.701416 466.902222,616.100281 
	C466.902222,605.988953 466.902222,596.186279 466.902222,585.930420 
	C469.312683,586.803223 472.782776,583.345886 473.423798,588.623352 
	C470.758240,588.038818 468.153961,586.564453 468.043304,590.696350 
	C467.856934,597.654480 467.904266,604.622803 468.062317,611.582092 
	C468.085632,612.608459 469.254608,613.608765 469.893524,614.621155 
	C470.609009,613.516113 471.892212,612.436279 471.942505,611.301758 
	C472.169830,606.172241 471.918976,601.021973 472.127350,595.890869 
	C472.194763,594.231323 473.137909,592.607483 473.680481,590.967285 
z"/>
<path fill="#C7160F" opacity="1.000000" stroke="none" 
	d="
M460.643738,277.293457 
	C459.569275,277.937256 458.168152,278.920868 456.764862,278.923981 
	C419.634521,279.006165 382.503967,278.997589 345.373505,278.943909 
	C343.998291,278.941895 342.623901,278.330627 341.123718,277.632080 
	C341.456268,277.175568 341.914215,277.090210 343.025269,277.367493 
	C344.499237,277.825562 345.320099,278.004272 346.141022,278.004578 
	C382.471771,278.018097 418.802521,278.023254 455.133240,277.984741 
	C456.403320,277.983398 457.672974,277.528503 458.942810,277.284973 
	C459.400574,277.248749 459.858337,277.212555 460.643738,277.293457 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M458.497070,277.146973 
	C457.672974,277.528503 456.403320,277.983398 455.133240,277.984741 
	C418.802521,278.023254 382.471771,278.018097 346.141022,278.004578 
	C345.320099,278.004272 344.499237,277.825562 343.365601,277.466248 
	C346.359375,277.095795 349.665985,276.895203 352.972473,276.896973 
	C387.998779,276.915802 423.025055,276.966827 458.497070,277.146973 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M473.751282,590.657227 
	C473.137909,592.607483 472.194763,594.231323 472.127350,595.890869 
	C471.918976,601.021973 472.169830,606.172241 471.942505,611.301758 
	C471.892212,612.436279 470.609009,613.516113 469.893524,614.621155 
	C469.254608,613.608765 468.085632,612.608459 468.062317,611.582092 
	C467.904266,604.622803 467.856934,597.654480 468.043304,590.696350 
	C468.153961,586.564453 470.758240,588.038818 473.304993,589.009399 
	C473.724884,589.510620 473.773499,589.928894 473.751282,590.657227 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M526.872192,589.186035 
	C526.322083,591.009888 525.215088,592.735291 524.551147,594.617065 
	C524.084412,595.940002 523.556213,598.236084 524.194641,598.793945 
	C528.609558,602.651794 525.741638,607.770020 526.816284,612.589417 
	C525.879761,609.959351 525.007996,606.870605 524.019409,603.368042 
	C523.391968,604.548950 522.792603,605.677002 521.900879,606.945312 
	C521.497925,606.610352 521.314636,606.139221 521.287598,605.659241 
	C520.654480,594.416443 519.990784,593.784485 508.447632,593.858887 
	C511.587982,593.612122 515.325684,592.697510 518.711121,593.424194 
	C523.062683,594.358337 524.266052,591.173828 526.872192,589.186035 
z"/>
<path fill="#C7160F" opacity="1.000000" stroke="none" 
	d="
M278.693939,464.802551 
	C278.967468,482.518982 278.967468,500.156616 278.967468,517.794250 
	C278.784790,517.828857 278.602081,517.863403 278.419403,517.898010 
	C278.177979,516.456299 277.936584,515.014587 277.452393,513.308350 
	C277.151550,497.656189 277.093475,482.268585 277.321442,466.322998 
	C277.822113,465.567810 277.933594,465.323486 277.941864,465.032043 
	C278.101410,464.929291 278.260925,464.826508 278.693939,464.802551 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M564.278931,613.528442 
	C564.277527,611.479309 564.277527,609.840698 564.277527,607.787964 
	C566.979492,607.787964 569.332458,607.787964 571.948853,607.787964 
	C571.948853,610.626648 571.948853,613.189026 571.948853,617.275452 
	C568.939331,615.966003 566.609802,614.952515 564.278931,613.528442 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M241.073944,598.855713 
	C241.073944,598.855713 241.144165,598.370361 241.207642,598.133545 
	C242.557266,597.934753 243.843414,597.972778 245.515594,597.995728 
	C245.997986,603.710999 246.094315,609.441467 246.165100,615.936340 
	C245.780380,617.221497 245.421234,617.742188 245.062073,618.262817 
	C244.250137,619.103638 243.438232,619.944397 242.404999,621.051636 
	C242.087921,619.704041 241.992157,618.090027 241.843124,615.565063 
	C241.551224,609.387939 241.312576,604.121826 241.073944,598.855713 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M646.274658,600.120728 
	C646.417053,598.720032 646.818176,597.393616 647.219299,596.067261 
	C649.096802,597.006775 652.244690,597.601501 652.611084,598.951233 
	C653.666260,602.838257 653.710510,607.025452 653.878479,611.105957 
	C653.912537,611.933289 652.748779,612.810059 652.137268,613.664062 
	C651.417358,612.742310 650.326294,611.920288 650.046814,610.880188 
	C649.187195,607.681335 648.638123,604.398987 647.614990,601.069031 
	C647.018799,600.724365 646.776062,600.459717 646.274658,600.120728 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M406.294556,600.934814 
	C406.779083,603.639893 406.878632,606.367310 406.951965,609.548218 
	C405.661133,609.420471 404.727692,605.996826 402.909851,609.890259 
	C400.264893,610.513245 397.626343,610.519470 395.136169,611.130432 
	C391.927551,611.917725 390.542725,610.773560 389.954803,607.060852 
	C394.680664,607.060852 399.060150,607.060852 403.649506,607.060852 
	C403.649506,604.989441 403.649506,603.540039 403.631500,601.669922 
	C404.378845,601.151855 405.144196,601.054443 406.294556,600.934814 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M438.910278,614.075073 
	C438.397430,608.054260 438.288330,601.988831 438.284210,595.467285 
	C439.649323,595.993347 440.909454,596.975647 442.508423,597.955444 
	C442.973022,598.646423 443.098724,599.339905 442.882874,600.458252 
	C441.425446,602.002502 440.309540,603.121826 439.193634,604.241150 
	C440.220001,605.220520 441.246338,606.199951 442.272705,607.179321 
	C442.335419,609.065369 442.398132,610.951355 442.504761,613.238953 
	C441.470459,613.770447 440.392242,613.900452 438.910278,614.075073 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M438.506195,587.524658 
	C438.887543,594.037903 434.586151,591.201294 431.339935,591.717407 
	C431.218201,589.724915 431.115112,588.037964 430.940399,585.179138 
	C433.883484,585.947388 436.173126,586.544983 438.506195,587.524658 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M474.504730,612.007935 
	C474.050629,608.104797 473.988434,604.187134 474.020752,599.932251 
	C475.492188,598.768677 476.869049,597.942139 478.634583,597.068604 
	C478.753052,601.706055 478.482880,606.390442 478.123749,611.532104 
	C476.988770,611.990601 475.942719,611.992004 474.504730,612.007935 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M232.978226,600.203247 
	C232.978226,600.203247 233.140167,600.362000 233.408142,600.597290 
	C234.075287,601.260681 234.474442,601.688843 234.873611,602.116943 
	C234.873611,602.116943 234.961853,602.032349 234.835190,602.460815 
	C234.634521,604.270569 234.560516,605.651794 234.486526,607.033020 
	C233.564682,606.775330 232.642853,606.517700 231.148315,606.099976 
	C231.148315,609.006531 231.242386,611.591553 231.082245,614.160706 
	C231.042160,614.803772 230.184357,615.395874 229.408630,615.666626 
	C229.072754,614.877502 229.030609,614.432983 228.988495,613.988525 
	C229.034195,613.238220 229.079895,612.487854 229.166611,610.946777 
	C229.494186,607.120483 229.780762,604.085022 230.067322,601.049561 
	C230.067322,601.049561 230.013123,600.993652 230.334778,600.963379 
	C231.430374,600.689880 232.204300,600.446594 232.978226,600.203247 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M234.487793,607.374329 
	C234.560516,605.651794 234.634521,604.270569 234.776733,602.478699 
	C235.224411,603.454773 235.603882,604.841431 235.988144,606.949585 
	C235.993805,608.111755 235.994675,608.552368 235.984283,609.328003 
	C235.977783,610.109802 235.982544,610.556580 235.993195,611.402710 
	C236.699127,612.512695 237.399170,613.223389 238.099197,613.934082 
	C238.741272,613.141052 239.847672,612.397095 239.937653,611.545776 
	C240.197968,609.082703 240.049515,606.576355 240.061523,604.087036 
	C240.364899,602.607910 240.668259,601.128784 241.022781,599.252686 
	C241.312576,604.121826 241.551224,609.387939 241.809082,615.083252 
	C241.438507,615.690430 241.048721,615.868408 239.929718,616.045044 
	C237.795624,616.047852 236.390747,616.051941 234.985886,616.056091 
	C234.873810,615.925903 234.761719,615.795776 234.643066,615.082886 
	C234.534622,612.689087 234.432755,610.878052 234.330872,609.067017 
	C234.383606,608.616577 234.436325,608.166077 234.487793,607.374329 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M635.269043,601.415649 
	C635.819031,603.644470 636.944580,606.354797 636.866455,609.030090 
	C636.753235,612.905945 637.802368,614.746887 642.297852,613.953003 
	C641.688293,618.438904 638.320618,614.890076 635.306763,616.880615 
	C635.306763,611.193909 635.306763,606.537659 635.269043,601.415649 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M214.900192,585.820740 
	C216.459274,587.075134 217.572601,588.330444 219.078049,590.027832 
	C209.950943,592.369751 209.950943,592.369751 210.219620,586.219360 
	C211.591293,586.090515 213.022858,585.956116 214.900192,585.820740 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M316.030273,601.363770 
	C318.102844,600.379211 320.154694,599.641113 322.270844,599.262695 
	C323.773102,598.994019 325.362091,599.210632 326.912384,599.210632 
	C327.045288,599.918091 327.178192,600.625549 327.311066,601.333008 
	C323.782990,602.510010 320.254913,603.687073 316.351685,604.913208 
	C315.996735,603.896179 316.016907,602.830017 316.030273,601.363770 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M489.887421,587.183594 
	C490.402802,589.526062 490.602783,592.098145 490.688507,593.200806 
	C486.414612,594.555420 483.039551,595.625183 479.343994,596.857666 
	C478.729584,593.973267 480.419128,592.651367 483.134766,593.044312 
	C487.674561,593.701172 489.225159,591.342773 489.887421,587.183594 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M309.527283,606.968994 
	C310.277527,603.271240 311.368774,599.472107 312.652771,595.375854 
	C313.955841,595.045654 315.066193,595.012573 316.592804,594.990967 
	C316.381805,596.697327 315.754547,598.392151 315.220703,600.434448 
	C310.669983,600.279175 311.816376,604.958008 309.527283,606.968994 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M504.004944,601.440430 
	C505.919098,600.445374 507.808960,599.682312 509.771820,599.349426 
	C511.202118,599.106689 512.728638,599.431458 514.212280,599.503296 
	C514.296509,600.168762 514.380737,600.834229 514.464966,601.499695 
	C511.338959,602.428772 508.212952,603.357910 504.703888,604.312073 
	C504.217896,603.510315 504.114929,602.683533 504.004944,601.440430 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M337.194092,610.174316 
	C337.842957,611.509644 338.491821,612.844971 339.228638,614.492981 
	C337.817871,615.214050 336.319061,615.622437 334.433807,616.011475 
	C334.017609,613.616211 333.987854,611.240417 334.311401,608.650635 
	C335.507874,609.015930 336.350983,609.595154 337.194092,610.174316 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M543.086914,616.342407 
	C546.126892,615.520569 549.227905,615.004395 552.349365,614.694580 
	C553.022827,614.627686 553.770081,615.304016 554.734985,615.870972 
	C551.042847,616.309875 547.099060,616.515869 543.086914,616.342407 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M337.506653,599.116943 
	C338.178986,600.706726 338.565338,602.436646 338.674133,604.532349 
	C336.990021,604.278381 335.583435,603.658630 334.176849,603.038879 
	C333.806763,601.275269 333.436676,599.511597 333.061035,597.362854 
	C334.443878,597.737549 335.832306,598.497375 337.506653,599.116943 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M239.727707,596.229614 
	C236.648392,596.356384 233.569092,596.483276 230.274475,596.359253 
	C232.732620,593.063904 235.860336,593.005676 239.570236,595.118530 
	C239.913086,595.694397 239.941742,595.861023 239.911057,596.079712 
	C239.851715,596.131836 239.727707,596.229614 239.727707,596.229614 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M442.951965,614.491089 
	C445.386108,614.810852 447.818970,615.504028 450.660767,616.240723 
	C447.781250,619.139954 445.074738,618.667847 442.951965,614.491089 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M533.832825,586.965210 
	C531.836304,587.968201 529.879211,588.583008 527.566528,589.221313 
	C528.681641,584.628296 530.279663,583.988342 533.832825,586.965210 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M536.893066,593.756409 
	C532.892639,594.758240 533.202026,592.140015 533.647339,589.267456 
	C534.856689,590.447754 535.873108,591.925598 536.893066,593.756409 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M228.600830,614.000854 
	C229.030609,614.432983 229.072754,614.877502 229.057312,615.656921 
	C228.999725,615.991882 228.999313,616.000000 228.562820,615.997437 
	C226.114258,615.930237 224.102188,615.865662 222.090118,615.801086 
	C222.108902,615.080627 222.127686,614.360107 222.261749,613.298218 
	C224.322433,613.308838 226.267822,613.661011 228.600830,614.000854 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M529.072449,616.398010 
	C531.363770,616.021606 533.735657,616.022400 536.552979,616.020508 
	C534.694092,619.489868 531.943176,618.335266 529.072449,616.398010 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M630.884521,603.664307 
	C630.850037,602.583008 630.976807,601.143433 631.103516,599.703857 
	C632.364319,599.842957 633.625183,599.982117 634.963867,600.457153 
	C633.709778,601.630676 632.377747,602.468323 630.884521,603.664307 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M228.889984,595.613281 
	C226.979874,596.267883 224.926147,596.576782 222.491470,596.953918 
	C223.230698,592.308167 225.931747,593.573120 228.889984,595.613281 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M222.209595,616.160522 
	C224.102188,615.865662 226.114258,615.930237 228.558777,615.996338 
	C227.024246,619.409485 224.696121,618.213135 222.209595,616.160522 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M234.115326,609.301880 
	C234.432755,610.878052 234.534622,612.689087 234.438354,614.926392 
	C234.126755,613.414062 234.013275,611.475403 234.115326,609.301880 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M497.720093,586.168945 
	C495.764618,586.365845 493.811340,586.174438 491.432404,585.948792 
	C493.192017,583.201477 495.431580,583.208801 497.720093,586.168945 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M522.445801,614.722351 
	C521.902771,613.203125 521.604065,611.431213 521.451660,609.288086 
	C521.961975,610.767761 522.325989,612.618774 522.445801,614.722351 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M453.173218,616.122803 
	C454.207336,615.744568 455.441193,615.635620 456.828308,615.790527 
	C455.778687,616.166992 454.575836,616.279541 453.173218,616.122803 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M235.056015,616.404663 
	C236.390747,616.051941 237.795624,616.047852 239.614731,616.120605 
	C238.676117,618.865662 236.993240,618.622009 235.056015,616.404663 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M326.790039,593.464905 
	C324.966400,593.806274 322.917572,593.906982 320.432373,593.948486 
	C322.185608,593.667603 324.375244,593.445923 326.790039,593.464905 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M399.140594,615.895508 
	C399.845520,614.810730 400.948975,613.790222 402.342712,612.628174 
	C401.601746,613.644348 400.570435,614.802063 399.140594,615.895508 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M642.845581,604.682617 
	C642.860474,603.700317 642.999023,602.386292 643.452698,601.002014 
	C643.501648,602.071472 643.235413,603.211182 642.845581,604.682617 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M419.747589,593.522583 
	C418.289154,593.839478 416.567505,593.906006 414.418640,593.911926 
	C415.822388,593.658325 417.653381,593.465271 419.747589,593.522583 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M408.915039,596.189941 
	C408.785187,597.112610 408.267639,598.033875 407.492889,599.149292 
	C407.666260,598.291809 408.096832,597.240234 408.915039,596.189941 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M642.827637,610.667969 
	C642.590271,610.434387 642.460999,609.874817 642.521667,609.159912 
	C642.786255,609.450317 642.861023,609.896118 642.827637,610.667969 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M241.006332,597.716431 
	C240.623062,597.590027 240.275238,597.171387 239.827560,596.491211 
	C239.727707,596.229614 239.851715,596.131836 240.136948,596.138611 
	C240.422180,596.145386 240.874878,596.259033 240.874878,596.259033 
	C240.930511,596.647400 240.986130,597.035828 241.006332,597.716431 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M314.561859,606.060181 
	C314.704926,606.252930 314.618927,606.567200 314.318665,607.017029 
	C314.180573,606.828979 314.256683,606.505310 314.561859,606.060181 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M315.719147,605.010193 
	C315.921509,605.334656 315.740387,605.587769 315.169922,605.756348 
	C314.980042,605.432495 315.165070,605.188110 315.719147,605.010193 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M504.003296,604.280701 
	C504.048035,604.571167 503.808319,604.832703 503.291626,605.088257 
	C503.249390,604.805420 503.484100,604.528625 504.003296,604.280701 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M338.800354,596.141479 
	C338.932190,596.371582 338.787109,596.692139 338.409088,597.148682 
	C338.291901,596.933716 338.407684,596.582764 338.800354,596.141479 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M410.737915,595.030151 
	C410.808960,595.269226 410.645721,595.546814 410.224854,595.818909 
	C410.017029,595.472290 410.195831,595.223938 410.737915,595.030151 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M528.069458,615.365051 
	C528.363586,615.073303 528.640076,615.207947 528.896423,615.777344 
	C528.627563,616.011353 528.352783,615.871094 528.069458,615.365051 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M339.921265,595.127747 
	C339.997742,595.378967 339.797455,595.676758 339.323517,596.024902 
	C339.248077,595.774902 339.446320,595.474609 339.921265,595.127747 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M240.826904,595.968445 
	C240.874878,596.259033 240.422180,596.145386 240.196289,596.086548 
	C239.941742,595.861023 239.913086,595.694397 239.864960,595.274719 
	C240.156631,595.240417 240.467789,595.459106 240.826904,595.968445 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M330.893494,594.578125 
	C330.697693,594.739380 330.364899,594.671997 329.890076,594.382080 
	C330.084198,594.222900 330.420319,594.286194 330.893494,594.578125 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M461.089447,616.332153 
	C461.154938,616.122009 461.320099,616.070618 461.741791,616.056274 
	C461.728607,616.225891 461.458862,616.358337 461.089447,616.332153 
z"/>
<path fill="#DA2A21" opacity="1.000000" stroke="none" 
	d="
M277.677307,465.028992 
	C277.933594,465.323486 277.822113,465.567810 277.425964,465.862244 
	C277.300568,465.648315 277.356659,465.337128 277.677307,465.028992 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M559.097046,616.342407 
	C559.164795,616.147034 559.328369,616.101624 559.742676,616.100220 
	C559.726501,616.260254 559.459778,616.376343 559.097046,616.342407 
z"/>
<path fill="#F3F3F4" opacity="1.000000" stroke="none" 
	d="
M169.359283,552.130188 
	C169.039078,549.074341 169.039078,546.307617 169.039078,543.003967 
	C171.245529,543.003967 173.538635,542.451416 175.334610,543.159607 
	C177.124741,543.865540 179.506088,545.754517 179.619431,547.274475 
	C179.732330,548.788391 177.655609,551.105896 175.977356,551.936768 
	C174.227402,552.803162 171.806839,552.315125 169.359283,552.130188 
z"/>
<path fill="#F3F3F4" opacity="1.000000" stroke="none" 
	d="
M556.623535,569.866638 
	C553.440552,570.266907 551.907837,569.709167 552.031738,566.277283 
	C552.294373,559.000977 552.111816,551.708679 552.111816,544.497742 
	C560.204590,543.535339 563.393188,545.906738 563.940002,553.133423 
	C564.164978,556.107361 564.089722,559.129639 563.854736,562.106506 
	C563.524841,566.285400 561.805420,569.456909 556.623535,569.866638 
z"/>
<path fill="#E2E3E4" opacity="1.000000" stroke="none" 
	d="
M465.921082,558.841675 
	C467.024292,554.629028 468.052246,550.817200 469.474701,545.542480 
	C471.075470,552.013123 472.314789,557.022522 473.636047,562.363342 
	C470.464874,562.363342 467.912933,562.363342 465.068085,562.363342 
	C465.389557,561.073364 465.617676,560.157959 465.921082,558.841675 
z"/>
<path fill="#E2E3E4" opacity="1.000000" stroke="none" 
	d="
M601.341187,562.459473 
	C598.828064,562.640076 596.698547,562.640076 593.573669,562.640076 
	C594.909668,557.308533 596.125488,552.456665 597.341370,547.604736 
	C597.767395,547.532715 598.193481,547.460632 598.619568,547.388550 
	C599.654663,552.351990 600.689758,557.315430 601.341187,562.459473 
z"/>
<path fill="#E2E3E4" opacity="1.000000" stroke="none" 
	d="
M674.880432,550.471985 
	C675.896729,554.468811 676.812378,558.107788 677.853149,562.244141 
	C674.933411,562.244141 672.330139,562.244141 669.180969,562.244141 
	C670.589661,556.851624 671.895081,551.854675 673.423950,546.002258 
	C674.068420,547.956604 674.424133,549.035339 674.880432,550.471985 
z"/>
<path fill="#E2E3E4" opacity="1.000000" stroke="none" 
	d="
M321.967896,553.583740 
	C316.769806,550.832581 320.548798,546.704956 319.254333,543.185852 
	C323.766479,542.872559 327.990234,542.251099 328.813171,547.519104 
	C329.534302,552.135193 326.286224,553.432922 321.967896,553.583740 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M484.787292,607.656006 
	C484.674713,606.891479 484.541931,606.534668 484.623657,606.236511 
	C485.160126,604.278870 485.649994,602.295471 486.422699,600.431396 
	C486.524231,600.186462 488.851196,600.360229 488.955139,600.702271 
	C489.598145,602.818909 490.589508,605.185059 490.164673,607.193237 
	C489.394043,610.835632 487.265564,611.471497 484.787292,607.656006 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M372.929565,606.699707 
	C371.691620,608.043030 370.476379,608.968384 369.261108,609.893738 
	C368.527802,607.722839 367.305237,605.560425 367.267334,603.377441 
	C367.248352,602.284607 369.389984,601.154297 370.554443,600.040894 
	C371.358795,601.461121 372.241913,602.844727 372.926178,604.320557 
	C373.177185,604.861938 372.957336,605.621582 372.929565,606.699707 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M268.136047,606.389648 
	C269.059845,607.067444 270.298340,608.323792 270.124237,608.566284 
	C269.237732,609.801392 268.118042,611.033203 266.795288,611.708435 
	C266.283447,611.969727 265.021851,610.762268 264.101807,610.223999 
	C264.709320,609.188721 265.212311,608.066406 265.970276,607.156494 
	C266.327515,606.727600 267.174286,606.706421 268.136047,606.389648 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M444.980713,601.069275 
	C447.052063,600.362183 449.081390,599.400391 451.211426,599.048584 
	C452.389923,598.853882 453.737061,599.680359 455.008179,600.046631 
	C454.238159,600.886536 453.619904,602.114685 452.667145,602.487122 
	C450.561615,603.310120 448.287689,603.702393 445.615845,603.868164 
	C445.093048,602.667053 445.036896,601.868164 444.980713,601.069275 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M454.719299,609.841064 
	C453.063263,610.604187 451.639771,611.188110 450.160583,611.426697 
	C449.934601,611.463135 448.947693,609.385864 449.171692,609.167542 
	C450.282349,608.084961 451.567963,606.878906 453.001312,606.491943 
	C456.225677,605.621399 455.791443,607.562195 454.719299,609.841064 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M444.710815,600.990967 
	C445.036896,601.868164 445.093048,602.667053 445.223755,603.860229 
	C445.298309,604.254456 445.264984,604.245483 444.978333,604.237305 
	C444.472443,604.502380 444.253204,604.775635 444.033936,605.048828 
	C444.033905,605.048828 444.001801,604.999329 443.761536,605.049133 
	C443.341187,605.383789 443.161072,605.668640 442.980957,605.953491 
	C442.932068,606.278870 442.810059,606.573914 442.443817,607.009033 
	C441.246338,606.199951 440.220001,605.220520 439.193634,604.241150 
	C440.309540,603.121826 441.425446,602.002502 442.937195,600.807739 
	C443.702332,600.792419 444.071594,600.852539 444.710815,600.990967 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M443.238251,605.913452 
	C443.161072,605.668640 443.341187,605.383789 443.784180,605.068359 
	C443.863220,605.316284 443.679382,605.594788 443.238251,605.913452 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M444.301422,605.047791 
	C444.253204,604.775635 444.472443,604.502380 444.967499,604.249023 
	C445.018494,604.528259 444.793701,604.787476 444.301422,605.047791 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M612.791016,601.861328 
	C612.953674,603.572449 613.473938,605.112061 612.977478,606.177979 
	C612.183960,607.881714 610.715149,609.270935 609.531860,610.793152 
	C608.405945,609.174927 606.572876,607.648926 606.346741,605.913452 
	C606.105225,604.059631 607.138245,601.858093 608.189087,600.164551 
	C608.410339,599.807922 611.126160,600.999268 612.791016,601.861328 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M552.120117,606.238525 
	C552.573425,607.490845 553.143005,609.100769 552.575623,609.852905 
	C551.825806,610.846924 550.229980,611.403381 548.903931,611.656677 
	C548.419617,611.749146 547.012939,610.165405 547.158325,609.812561 
	C547.600159,608.740417 548.491333,607.772034 549.410645,607.007507 
	C549.997803,606.519287 550.944763,606.463806 552.120117,606.238525 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M334.128052,603.450439 
	C335.583435,603.658630 336.990021,604.278381 338.742615,604.905762 
	C339.088593,604.913452 339.050507,604.932495 339.129639,605.184204 
	C339.529999,605.573303 339.851196,605.710632 340.172424,605.848022 
	C340.172424,605.848022 339.992615,605.946228 340.029236,606.235352 
	C339.375824,607.612488 338.685791,608.700500 337.995789,609.788513 
	C337.995789,609.788513 337.612793,610.017456 337.403442,610.095886 
	C336.350983,609.595154 335.507874,609.015930 334.423462,608.218506 
	C334.147888,606.620789 334.113586,605.241394 334.128052,603.450439 
z"/>
<path fill="#FBDFDA" opacity="1.000000" stroke="none" 
	d="
M323.028870,611.806213 
	C322.119019,610.742615 321.014587,608.935059 321.148804,608.837891 
	C322.726471,607.695312 324.489868,606.809143 326.204315,605.855408 
	C326.696655,606.421265 327.189026,606.987122 327.681366,607.552979 
	C326.257507,608.987610 324.833618,610.422180 323.028870,611.806213 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M513.685547,606.197998 
	C513.964600,607.500488 514.336609,609.071472 513.715454,609.852783 
	C512.919434,610.854126 511.373535,611.435547 510.045654,611.740662 
	C509.591644,611.845032 508.186310,610.163208 508.297119,609.990295 
	C509.025024,608.853821 509.960724,607.799438 511.009979,606.939697 
	C511.608795,606.449036 512.550110,606.376465 513.685547,606.197998 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M338.373383,609.831482 
	C338.685791,608.700500 339.375824,607.612488 340.111603,606.174255 
	C342.535492,606.472595 344.999817,606.915649 347.253143,607.861572 
	C348.286926,608.295471 348.906158,609.716919 349.713409,610.690552 
	C348.245544,610.930664 346.744141,611.488342 345.317627,611.337524 
	C343.101959,611.103210 340.937286,610.386597 338.373383,609.831482 
z"/>
<path fill="#FBD3CE" opacity="1.000000" stroke="none" 
	d="
M340.085846,605.596558 
	C339.851196,605.710632 339.529999,605.573303 339.139038,605.169312 
	C339.379272,605.050110 339.689270,605.197632 340.085846,605.596558 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M299.299133,608.651306 
	C298.484100,606.551819 297.802338,604.818420 297.120575,603.085083 
	C296.586517,603.048584 296.052429,603.012085 295.518341,602.975586 
	C294.351715,605.562927 293.291016,608.205994 291.972198,610.713257 
	C291.344177,611.907288 290.224823,612.842834 289.327972,613.895447 
	C288.233643,612.787048 286.660858,611.866455 286.137634,610.533813 
	C284.750580,607.001099 283.696869,603.320007 282.784882,599.630127 
	C282.546631,598.666199 283.310669,597.454529 283.614410,596.356628 
	C284.411926,597.203186 285.589722,597.918701 285.935303,598.921082 
	C286.952789,601.872314 287.640259,604.937378 288.456909,607.957825 
	C289.140259,607.955139 289.823608,607.952454 290.506958,607.949707 
	C291.321472,605.569336 292.002625,603.131531 292.994995,600.827759 
	C293.719147,599.146729 294.853333,597.642273 295.804718,596.059143 
	C296.954742,597.449829 298.336639,598.711121 299.201202,600.261047 
	C300.547302,602.674255 301.541992,605.283508 302.685608,607.809631 
	C303.151001,607.724365 303.616425,607.639038 304.081818,607.553711 
	C305.391663,603.717651 306.701508,599.881531 308.011353,596.045410 
	C308.618622,596.202393 309.225891,596.359375 309.833160,596.516357 
	C309.596771,598.108093 309.512085,599.741394 309.089630,601.282104 
	C308.398987,603.801147 307.716766,606.368774 306.599152,608.709717 
	C305.690979,610.612061 304.149445,612.211975 302.888794,613.945984 
	C301.736633,612.303101 300.584503,610.660278 299.299133,608.651306 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M244.987488,618.628174 
	C245.421234,617.742188 245.780380,617.221497 246.254959,616.402344 
	C246.895721,614.523499 247.809601,612.958679 247.874039,611.359619 
	C248.072525,606.434387 247.946381,601.496094 247.946381,596.562866 
	C248.506622,596.348633 249.066849,596.134460 249.627090,595.920227 
	C250.428986,597.302917 251.852951,598.655029 251.922607,600.073669 
	C252.190948,605.538574 252.193893,611.031494 251.973892,616.501038 
	C251.768829,621.599487 248.653336,624.176514 243.835724,623.141479 
	C244.198654,621.743958 244.555786,620.368774 244.987488,618.628174 
z"/>
<path fill="#DA2820" opacity="1.000000" stroke="none" 
	d="
M240.015564,603.791931 
	C240.049515,606.576355 240.197968,609.082703 239.937653,611.545776 
	C239.847672,612.397095 238.741272,613.141052 238.099197,613.934082 
	C237.399170,613.223389 236.699127,612.512695 236.220779,611.257446 
	C236.293488,610.139526 236.144516,609.566284 235.995544,608.993042 
	C235.994675,608.552368 235.993805,608.111755 235.997711,607.340881 
	C237.324860,605.839355 238.647232,604.668091 240.015564,603.791931 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M229.677902,601.114136 
	C229.780762,604.085022 229.494186,607.120483 229.105164,610.574707 
	C229.097961,607.721863 229.193207,604.450256 229.677902,601.114136 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M232.812698,599.915771 
	C232.204300,600.446594 231.430374,600.689880 230.352966,600.997437 
	C230.915390,600.583862 231.781281,600.106079 232.812698,599.915771 
z"/>
<path fill="#C54A44" opacity="1.000000" stroke="none" 
	d="
M234.890671,601.763611 
	C234.474442,601.688843 234.075287,601.260681 233.520569,600.582520 
	C233.879257,600.691711 234.393494,601.050964 234.890671,601.763611 
z"/>
<path fill="#CD2E26" opacity="1.000000" stroke="none" 
	d="
M235.984283,609.328003 
	C236.144516,609.566284 236.293488,610.139526 236.214905,610.858032 
	C235.982544,610.556580 235.977783,610.109802 235.984283,609.328003 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
